import { Component, OnInit, ViewChildren, QueryList, ElementRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralservicesService } from '../../generalservice';
import { userservice } from '../userservice'
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.scss']
})
export class ProductdetailsComponent implements OnInit {
  @ViewChildren('items') liItems: QueryList<ElementRef>
  ProVariantsLength: any;
  Products: any;
  HomeUrl: any;
  GST: any;
  ShowCategories: any;
  ShowProducts: any;
  ShowRelatedProducts: any;
  Logindetails: any;
  addedItems: any;
  addtocartItem: any;
  totalcartItems: any;
  RoleId: any;
  productID: number;
  ProductVariants: any =[];
  productPrice: any;
  selectedVariant: any;
  addcart: boolean = true;
  whatsAppProductURL: string;
  size: any;
  target: string;
  public ckeditorContent: any;


  constructor(public http: HttpClient, public router: Router, public AR: ActivatedRoute, public generalService: GeneralservicesService, public user: userservice) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
    //debugger;
    this.Logindetails = JSON.parse(localStorage.getItem('LoginDetails'));
    if (this.Logindetails != null)
    {
      this.RoleId = this.Logindetails.RoleID;

    }
    
  }

  ngOnInit() {
    //debugger
    this.Products = JSON.parse(localStorage.getItem('ProductsDetails'));
    this.productID = this.Products.ProductID;//parseInt(this.AR.snapshot.paramMap.get('id'));

    this.ShowDetails();
    this.GetProductVariants();
    //this.ViewProducts();
    this.toTop();
    
  }
 
  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
    this.liItems.forEach((item, index) => {
      if (index === 0) { (item.nativeElement as HTMLElement).click(); }
    });
  }
  toTop() {
    //debugger
    window.scroll(0, 0);
  }

  GetProductVariants() {
    //debugger;
    var url = "/api/Admin/GetProductVariantsBasedOnProductId" 
    var UploadFile = new FormData();
    UploadFile.append("ProductID", this.productID.toString());
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger;
      this.ProductVariants = [];
      console.log  (this.ProductVariants)
      this.ProductVariants = data.filter(c => c.Status == true);
      if (this.ProductVariants != [] || this.ProductVariants != undefined || this.ProductVariants != null) {
        this.changePrice(this.ProductVariants[0]);
        this.ProVariantsLength = this.ProductVariants.length;
      }
    
      for (var i = 0; i < this.ProductVariants.length; i++) {
        this.ProductVariants[i].Description = decodeURIComponent(this.ProductVariants[i].Description);
      }
      //console.log(JSON.stringify(this.ProductVariants));
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }


  sendWhatsapp(item1) {
    //debugger
    var prodName = ""
    var str = item1.ProductName.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
    //this.whatsAppProductURL = "https://ykmart.breakingindiaapp.com/%23/Product/" + prodName 
    this.whatsAppProductURL = "https://agrobin.in/%23/productdetails/" + prodName
    window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
  }


  changePrice(data) {
    //debugger
    //this.addcart = false;
    
    this.size = data.SizeID;
    this.GST = data.GST;
  //  this.ProductVariants[0] = data.AdminPrice1;
    this.Products.AdminPrice = data.AdminPrice;    
    this.Products.Size = data.Size;

    //this.productPrice = data.AdminPrice;

    this.Products.SizeID = data.SizeID;

    this.selectedVariant = data;

    this.productPrice = data.AdminPrice1;

  }

  getRlated(items) {
    debugger
    var url = '/api/Admin/Product_CRUD';
    var formdata = new FormData();
    formdata.append("ProductXml", "");
    formdata.append("ImageXml", "");
    formdata.append("SizesXml", "");
    formdata.append("ReletedProductXml", "");
    formdata.append("Flag", "5");
    this.generalService.PostData(url, formdata).then((data: any) => {
      //debugger
      if (data != null) {
        var pros = data.filter(t => t.ProductID == items.value);
        this.Products = pros[0];
        this.Products = this.Products.length;

      }
      for (var i = 0; i < this.Products.length; i++) {
        this.Products[i].Description = decodeURIComponent(this.Products[i].Description);
      }
    })

  }
  payment() {
    this.router.navigateByUrl('/Addtocart');
  }
  ShowDetails() {
    debugger
    
    var url = '/api/Admin/Get_ReletedProducts';
    var formdata = new FormData();
    //formdata.append("ProductXml", "");
    //formdata.append("ImageXml", "");
    //formdata.append("SizesXml", "");
    //formdata.append("ReletedProductXml", "");
    formdata.append("ProdID", this.Products.ProductID);
    this.generalService.PostData(url, formdata).then((data: any) => {
      debugger
      if (data != null) {
        this.ShowRelatedProducts = data;

        for (var i = 0; i < this.ShowRelatedProducts.length; i++) {
          this.ShowRelatedProducts[i].Description = decodeURIComponent(this.ShowRelatedProducts[i].Description);
        }
      }
      
    })


  }
  Addtocart(menu) {
    debugger
    if (this.size != null) {
      let savecart: any = [];
      this.addedItems = [];
      this.addtocartItem = []
      this.addedItems = JSON.parse(localStorage.getItem('Productdetails'));
      if (this.addedItems != null) {
        if (this.addedItems.length > 0) {
          for (var i = 0; i < this.addedItems.length; i++) {
            this.addtocartItem.push(this.addedItems[i]);
          }
        }
      }
      //var newaddeditem = this.addedItems;
      var check = true;
      if (this.addedItems != null) {
        localStorage.setItem("totalcartitems", JSON.stringify(this.addedItems.length));
        for (var i = 0; i < this.addedItems.length; i++) {
          debugger
          if (this.addedItems[i].ProductID == menu.ProductID && this.addedItems[i].SizeID == menu.SizeID) {
            check = false;
          }
        }
        if (check == true) {
         debugger

          this.addtocartItem.push(menu);
          localStorage.setItem('Productdetails', JSON.stringify(this.addtocartItem));
          this.totalcartItems = this.addtocartItem.length;
          localStorage.setItem("totalcartitems", JSON.stringify(this.addtocartItem.length));
          this.user.edituser(this.addtocartItem.length);
          this.generalService.ShowAlert('SUCCESS', "Product added to cart  successfully", 'success');
        }
        else if (check == false) {
          this.addtocartItem.push(menu);
          this.generalService.ShowAlert('WARNING', "This Item is Already in your Cart", 'warning');
          //this.totalcartItems = this.addtocartItem.length;
          //localStorage.setItem('Productdetails', JSON.stringify(menu));
          //localStorage.setItem("totalcartitems", JSON.stringify(this.addtocartItem.length));
          //this.header.getcartitems(this.addtocartItem.length);
          //this.generalService.ShowAlert('SUCCESS', "Product Added in Your cart Successfully", 'success');
        }

      }
      else {
        if (check == true) {
          debugger

          this.addtocartItem = []
          this.addtocartItem.push(menu);
          localStorage.setItem('Productdetails', JSON.stringify(this.addtocartItem));
          this.totalcartItems = this.addtocartItem.length;
          localStorage.setItem("totalcartitems", JSON.stringify(this.addtocartItem.length));
          this.user.edituser(this.addtocartItem.length);
          this.generalService.ShowAlert('SUCCESS', "Product Added to Cart  Successfully", 'success');
          this.user.edituser(this.addtocartItem.length);

        }
      }
    }
    else {
      this.generalService.ShowAlert('WARNING', "Please select size", 'warning');
    }


  }
  ViewProducts(menu) {
    //debugger
    localStorage.setItem('ProductsDetails', JSON.stringify(menu));
    this.router.navigate(['/productdetails', menu.ProductName, menu.ProductID]);
    this.ngOnInit()
    window.location.reload(true);
    this.router.onSameUrlNavigation = 'reload';
  }
  ViewProducts1(menu) {
    //debugger
    localStorage.setItem('ProductsDetails', JSON.stringify(menu));
    this.router.navigate(['/productdetails', menu.ProductName, menu.ProductID]);
    this.ngOnInit()
    window.location.reload();
    this.router.onSameUrlNavigation = 'reload';
  }
  
  onChange(event: any) {
    setTimeout(() => {
      this.ckeditorContent = event;
    });
  }
  onReady(event: any) {
    event.editor.focus();

  }
  onFocus(event: any) {
    console.log("editor is focused");

  }
  onBlur(event: any) {

  }
}
