import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { RequestOptions, Headers, Http } from '@angular/http';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent1 implements OnInit {
  ApiURL: any;

  constructor(public httpClient: HttpClient, public http: Http) {
  //  this.ApiURL = 'http://localhost:51068/token';
    debugger
    this.ApiURL= 'http://9roads.in:3002/validateUser'
  }

  ngOnInit() {
    debugger
    this.ContactUs();
  }

  ContactUs() {
    debugger
   //let body = {
   //  //'grant_type': 'password',
   //  //"username": 'Anurag',
   //  //"password": '123456'

   //  "grant_type=password&username='Anurag'&password='password'
   // }

    let urlSearchParams = new URLSearchParams();
    urlSearchParams.set('grant_type', 'password');
    urlSearchParams.set('username', 'ravi9587');
    urlSearchParams.set('password', '18242958');

    let body = urlSearchParams.toString();

    


    let headersk = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic dmlqYXlrdW1hckBnbWFpbC5jb206VmlqYXlAMTIzNA ==' 
    //  'Authorization': basic
      // headers.append('Authorization', this.basic);
    })
   // let options = { headers: headersk };

    //let headers = new Headers();
    //headers.append('Content-Type', 'application/json');
    //headers.append('Authorization', 'Basic dmlqYXlrdW1hckBnbWFpbC5jb206VmlqYXlAMTIzNA==');
    //let options = new RequestOptions({ headers: headers });

    let headers = new Headers({ 'Accept': 'application/json' });
    headers.append('Authorization', 'Basic dmlqYXlrdW1hckBnbWFpbC5jb206VmlqYXlAMTIzNA==');
    headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Authorization');
    headers.append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, PATCH');
    headers.append('Access-Control-Allow-Origin', '*');

    let options = new RequestOptions({ headers: headers });
    this.http.post(this.ApiURL, body, options).subscribe(data => {
      debugger
    })
  }


 // httpHeaders.append("Authorization", 'Basic dmlqYXlrdW1hckBnbWFpbC5jb206VmlqYXlAMTIzNA==');
  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

}
