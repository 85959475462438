import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
}; 
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardModule } from 'primeng/card';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule } from 'angular-calendar';
import { ToastrModule } from 'ngx-toastr';
import { PipesModule } from './theme/pipes/pipes.module';


//import { UserIdleModule } from 'angular-user-idle';
//import { ImageCropperModule } from 'ngx-image-cropper';
//import { MultiSelectModule } from 'primeng/primeng';


import { routing } from './app.routing';
import { AppSettings } from './app.settings';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HeaderComponent1 } from './theme/components/header/header.component';
import { FooterComponent1 } from './theme/components/footer/footer.component';
import {FooterComponent } from './customer/footer/footer.component';
import { HeaderComponent } from './customer/header/header.component';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { SideChatComponent } from './theme/components/side-chat/side-chat.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { HttpModule } from '@angular/http'
import { CheckboxModule } from 'primeng/checkbox';

//import { AutoCompleteModule } from 'primeng/autocomplete';


//import { UOMComponent } from './pages/admin/uom/uom.component';
//import { SubCategoriesComponent } from './pages/admin/sub-categories/sub-categories.component';
//import { BrandComponent } from './pages/admin/brand/brand.component';
//import { CategoriesComponent } from './pages/admin/categories/categories.component';
//import { OrderComponent } from './pages/admin/order/order.component';
//import { PrimengComponent } from './pages/admin/primeng/primeng.component';

import { AddtocartComponent } from './pages/addtocart/addtocart.component';
import { PagelayoutComponent } from './pages/pagelayout/pagelayout.component';
import { PageHeaderComponent } from './pages/page-header/page-header.component';
import { PageFooterComponent } from './pages/page-footer/page-footer.component';
import { HomeComponent } from './pages/home/home.component';
//import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ProductdetailsComponent } from './pages/productdetails/productdetails.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
//import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
//import { AllcategoriesComponent } from './allcategories/allcategories.component';
import { MultiSelectModule } from 'primeng/MultiSelect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ShowDetailsComponent } from './pages/show-details/show-details.component';
//import {IndexComponent } from './app/index/index.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FilterPipe } from './filter.pipe';
//import { FilterPipe } from './filter.pipe';
import {ContactUsComponent1}from './pages/dashboard/contact-us/contact-us.component'
import { RadioButtonModule } from 'primeng/radiobutton';
import { MyordersComponent } from './pages/myorders/myorders.component';
import { VendorRegistrationComponent } from './pages/vendor-registration/vendor-registration.component'
import { DatePipe } from '@angular/common';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { ReturnpolicyComponent } from './pages/returnpolicy/returnpolicy.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { TermsandconditionsComponent } from './pages/termsandconditions/termsandconditions.component'
import { MyWalletComponent } from './pages/my-wallet/my-wallet.component'
import { MyRewardsComponent } from './pages/my-rewards/my-rewards.component'
//import { google } from '@agm/core/services/google-maps-types';



@NgModule({  
  imports: [
    BrowserModule, OverlayModule, PortalModule,
    FormsModule,
    BrowserAnimationsModule,
    PerfectScrollbarModule,
    NgbModule,
    MultiselectDropdownModule,
  //  UserIdleModule.forRoot({ idle: 300, timeout: 300, ping: 120 }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBNcjxo_35qnEG17dQvvftWa68eZWepYE0',
      libraries: ['places'],
    }),
    CalendarModule.forRoot(),
    ToastrModule.forRoot(), 
    PipesModule, ReactiveFormsModule,
    HttpClientModule,
    routing, HttpModule,
    TableModule, InputTextareaModule, CardModule,
    MultiSelectModule,
    AutoCompleteModule,
    ProgressSpinnerModule, PipesModule, RadioButtonModule, CheckboxModule
    //ImageCropperModule
    

  ],
  declarations: [
    AppComponent,
    PagesComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    BackTopComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    FlagsMenuComponent,
    SideChatComponent,
    FavoritesComponent,
    NotFoundComponent,
    HeaderComponent1,
    FooterComponent1,
    ContactUsComponent1,
    VendorRegistrationComponent,
    //CategoriesComponent,
    //BrandComponent,
    //OrderComponent,
    ////PrimengComponent,
    //UOMComponent,
    //SubCategoriesComponent,
    SpinnerOverlayComponent,
    AboutUsComponent,
  //  AllcategoriesComponent
    PagelayoutComponent,
    HomeComponent,
    UserProfileComponent,
    PageHeaderComponent,
    MyordersComponent,
    ContactUsComponent1,
    AddtocartComponent,
    ProductdetailsComponent,
    ContactUsComponent,
    CheckoutComponent, PageFooterComponent, ShowDetailsComponent, FilterPipe,
    PrivacypolicyComponent,
    ReturnpolicyComponent,
    ForgotPasswordComponent,
    TermsandconditionsComponent,
    MyWalletComponent,
    MyRewardsComponent
  ],
  providers: [ 
    AppSettings, DatePipe,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SpinnerOverlayComponent
  ]
})
export class AppModule { }
