import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class GeneralservicesService {

  constructor(public http: HttpClient) { }

  ShowAlert(title, text, type) {
    Swal.fire({
      title: title,
      text: text,
      type: type,
      confirmButtonText: 'Ok'
    })
  }
  //Global Method to retrieve data 
  GetData(url) {
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
     
        url = data.Webservice + url
        debugger
        this.http.get(url).subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  PostData(url, PostParam) {
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        url = data.Webservice + url
        this.http.post(url, PostParam).subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  PostData2(url, PostParam, PostParam2) {
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        url = data.Webservice + url
        this.http.post(url, PostParam, PostParam2).subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }





}
