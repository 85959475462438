import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {
  data1: any;

  constructor(public router: Router) {


  }

  ngOnInit() {
  }

  login(id) {
    debugger;
    localStorage.removeItem('log');
    localStorage.setItem("log", id)
    this.data1 = localStorage.getItem("log")
    this.router.navigate(['/login']);

  }

  AdminLogin() {
    localStorage.setItem("RoleId", "1");
    this.router.navigate(['/login'])
  }
  VendorLogin() {
    localStorage.setItem("RoleId", "2");
    this.router.navigate(['/login'])
  }

  Scroll(ID) {
    debugger
    sessionStorage.removeItem('ScrollID');
    sessionStorage.setItem('ScrollID', ID);
    this.router.navigateByUrl('/Addtocart', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/Home']);
    });
  }
}
