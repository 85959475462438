import { Menu } from './menu.model';

//export const AdminItems = [ 
//  new Menu(1, 'Dashboard', 'Dashboard', null, 'tachometer', null, false, 0),
//  new Menu(2, 'Orders', '/Admin/Orders', null, 'tachometer', null, false, 0),
//  new Menu(3, 'Products', '/Admin/Products', null, 'tachometer', null, false, 0),
//  //new Menu(4, 'Vendors', '/Admin/Dealers', null, 'tachometer', null, false, 0),
//  new Menu(5, 'Dealers', '/Admin/Dealers', null, 'tachometer', null, false, 0),
//  new Menu(6, 'Agents', '/Admin/Agents', null, 'tachometer', null, false, 0),
//  new Menu(7, 'Customers', '/Admin/Customers', null, 'tachometer', null, false, 0),
//  new Menu(8, 'Vendors', '/Admin/Vendors', null, 'tachometer', null, false, 0),
//  //new Menu(9, 'Master Tables', '', null, 'table', null, true, 0),
//  new Menu(10, 'Brands', '/Admin/Brands', null, 'tachometer', null, false,0),
//  new Menu(11, 'Categories', '/Admin/Categories', null, 'tachometer', null, false, 0),
//  new Menu(12, 'Sub Categories', '/Admin/SubCategories', null, 'tachometer', null, false, 0),
//  new Menu(13, 'Sub SubCategories', '/Admin/SubSubCategories', null, 'tachometer', null, false, 0),
//  new Menu(14, 'UOM', '/Admin/UOM', null, 'tachometer', null, false, 0),
//  new Menu(15, 'VendorProducts', '/Admin/VendorProducts', null, 'tachometer', null, false, 0),
//  new Menu(16, 'AddStock', '/Admin/AddStock', null, 'tachometer', null, false, 0),
//  new Menu(17, 'VendorOrders', '/Admin/vendororders', null, 'tachometer', null, false, 0),
//]



export const AdminItems = [
  new Menu(1, 'Dashboard', 'Dashboard', null, 'tachometer', null, false, 0),
  new Menu(2, 'Orders', '/Admin/Orders', null, 'tachometer', null, false, 0),

  new Menu(3, 'Products', null, null, 'laptop', null, true, 0),

  new Menu(15, 'VendorProducts', '/Admin/VendorProducts', null, 'tachometer', null, false, 3),

  new Menu(4, 'Products', '/Admin/Products', null, 'eye', null, false, 3),
  new Menu(5, 'VendorOrders', '/Admin/vendororders', null, 'tachometer', null, false, 3),
  new Menu(6, 'AddStock', '/Admin/AddStock', null, 'tachometer', null, false, 3),  

  new Menu(7, 'Users', null, null, 'laptop', null, true, 0),

  new Menu(8, 'Dealers', '/Admin/Dealers', null, 'tachometer', null, false, 7),
  new Menu(9, 'Agents', '/Admin/Agents', null, 'tachometer', null, false, 7),
  new Menu(10, 'Customers', '/Admin/Customers', null, 'tachometer', null, false, 7),
  new Menu(11, 'Vendors', '/Admin/Vendors', null, 'tachometer', null, false, 7),

  new Menu(12, 'Masters', null, null, 'laptop', null, true, 0),
  new Menu(13, 'Brands', '/Admin/Brands', null, 'tachometer', null, false, 12),
  new Menu(14, 'Categories', '/Admin/Categories', null, 'tachometer', null, false, 12),
  new Menu(26, 'Sub Categories', '/Admin/SubCategories', null, 'tachometer', null, false, 12),
  new Menu(16, 'Sub SubCategories', '/Admin/SubSubCategories', null, 'tachometer', null, false, 12),
  new Menu(17, 'UOM', '/Admin/UOM', null, 'tachometer', null, false, 12),
  new Menu(18, 'Product Groups', '/Admin/productgroups', null, 'tachometer', null, false, 12),
  new Menu(19, 'Reports', null, null, 'laptop', null, true, 0),
  new Menu(20, 'ProductSaleReport', '/Admin/ProductSaleReport', null, 'tachometer', null, false, 19),
  new Menu(21, 'Vendorsalesreport', '/Admin/Vendorsalesreport', null, 'tachometer', null, false, 19),
  new Menu(22, 'Agentsalesreport', '/Admin/Agentsalesreport', null, 'laptop', null, false, 19),
  new Menu(23, 'Payments', null, null, 'tachometer', null, true, 0),
 // new Menu(24, 'Vendor Payments', '/Admin/VendorPayments', null, 'laptop', null, false, 23),
  new Menu(25, 'Agent Payments', '/Admin/AgentPayments', null, 'laptop', null, false, 23),
  new Menu(27, 'District Master', '/Admin/DistrictMaster', null, 'tachometer', null, false, 12),
  new Menu(28, 'Village Master', '/Admin/VillageMaster', null, 'laptop', null, false, 12),
  new Menu(29, 'Mandal Master', '/Admin/MandalMaster', null, 'tachometer', null, false, 12),
  new Menu(30, 'Settings', '/Admin/Settings', null, 'tachometer', null, false, 0),
  new Menu(31, 'Promo Code', '/Admin/Excelupload', null, 'laptop', null, false, 0),
  new Menu(32, 'Send Sms', '/Admin/SendSms', null, 'tachometer', null, false, 0),

] 



///////vendor Menus//////////////////////////
export const VendorItems = [
  //new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),
  //new Menu(2, 'Vendor Products', '/Vendor/VendorProducts', null, 'user', null, false, 0),
  //new Menu(5, 'My Profile', '/Vendor/VendorProfile', null, 'user-o', null, false, 0),
  new Menu(3, 'Products', '/Vendor/Products', null, 'user', null, false, 0),
  new Menu(4, 'Stock', '/Vendor/Stock', null, 'user', null, false, 0),
  new Menu(5, 'Product Details', '/Vendor/ProductDetails', null, 'user', null, false, 0),
  new Menu(6, 'Orders', '/Vendor/VendorOrders', null, 'user', null, false, 0),
  new Menu(7, 'Profile', '/Vendor/VendorProfile', null, 'user', null, false, 0),
  new Menu(8, 'Reports', null, null, 'laptop', null, true, 0),
  new Menu(9, 'Vendorsalesreport', '/Vendor/VendorSalesReport', null, 'tachometer', null, false, 8),

  //new Menu(8, 'Vendor Payments', '/Vendor/VendorPayments', null, 'user', null, false, 0),



]

/////////////Customer menus/////////////////////////////
export const CustomerItems = [
  new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),
  new Menu(6, 'Vendors', '/Vendors', null, 'wrench', null, false, 0),
  new Menu(7, 'accessories', '/accessories', null, 'wrench', null, false, 0),
]

export const horizontalMenuItems = [ 
  new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),
 
]


