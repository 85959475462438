import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  constructor(public router: Router
  ) {
  }

  ngOnInit() {
    this.toTop();
  }
  toTop() {
    //debugger
    window.scroll(0, 0);
  }
  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }
}
