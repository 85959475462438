import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header1',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  router: any;
  constructor() { }

  ngOnInit() {
  }


  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }
}
