import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralservicesService } from '../../generalservice';
import { SelectItem } from 'primeng/api';
import { map, throttle } from 'rxjs/operators';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-vendor-registration',
  templateUrl: './vendor-registration.component.html',
  styleUrls: ['./vendor-registration.component.scss']
})
export class VendorRegistrationComponent implements OnInit {
  public form: FormGroup;
  public name: AbstractControl;
  public mobile: AbstractControl;
  public email: AbstractControl;
  public password: AbstractControl;
  public confirmPassword: AbstractControl;
  public GstNo: AbstractControl;
  public catType: AbstractControl;
  public city: AbstractControl;
  public area: AbstractControl;
  public address: AbstractControl;
  public pincode: AbstractControl;
  public landmark: AbstractControl;
  public outletname: AbstractControl;
  public StateNameid: AbstractControl;
  HomeUrl: any;
  image: any;
  imagefile: any;
  MobileNo: any;
  Emailid: any;
  checkNo: any;
  img1: any;
  checkemail: any;
  IsOutlet: boolean = false;
  category: any;
  StateNames: any;
  States: any = [];
    categories: any=[];
  Outlets: any[];
  location: any;
  Vehicles: any;
  constructor(public http: HttpClient, public router: Router,
    public generalService: GeneralservicesService,public fb: FormBuilder, public httpClient: HttpClient) {
    this.httpClient.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
    this.form = fb.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      mobile: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      password: ['', [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]],
      confirmPassword: ['', [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]],
      catType: ['', Validators.required],
      GstNo: ['',],
      city: ['', Validators.required],
      area: ['', Validators.required],
      address: ['', Validators.required],
      landmark: ['', Validators.required],
      pincode: ['', Validators.required],
      outletname: ['', Validators.required],
      StateNameid: ['', Validators.required],
      //Pincode: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])]


    }, { validator: matchingPasswords('password', 'confirmPassword') });

    this.name = this.form.controls['name'];
    this.mobile = this.form.controls['mobile'];
    this.email = this.form.controls['email'];
    this.password = this.form.controls['password'];
    this.confirmPassword = this.form.controls['confirmPassword'];
    this.pincode = this.form.controls["pincode"];
    this.landmark = this.form.controls["landMark"];
    this.catType = this.form.controls["VehicleType"];
    this.GstNo = this.form.controls["GstNo"];
    this.landmark = this.form.controls["landmark"];
    this.area = this.form.controls["area"];
    this.city = this.form.controls["city"];
    this.address = this.form.controls["address"];
    this.pincode = this.form.controls["pincode"];
    this.outletname = this.form.controls["outletname"];
    this.StateNameid = this.form.controls["StateNameid"];

  }

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

  ngOnInit() {
    this.getCategories();
    this.getStates();
  }
  fileChange(input) { // For Customer Image
    debugger
    const reader = new FileReader();
    if (input.files.length) {
      const file = input.files[0];
      reader.onload = () => {
        this.imagefile = reader.result;
      }
      reader.readAsDataURL(file);
      var selectedFile = file;
      var idxDot = selectedFile.name.lastIndexOf(".") + 1;
      var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        var UploadFile = new FormData();
        UploadFile.append("PostedFile", file);
        var url = '/api/Values/VendorLogos';
        this.generalService.PostData(url, UploadFile).then((data: any) => {
          if (data != null) {
            debugger
            this.imagefile = data;
            this.IsOutlet = true;
            this.image = this.HomeUrl + this.imagefile;
          }
        });
      }
      else {
        alert("Only jpg/jpeg, png files are allowed!");
      }

    }
  }
/* check mobile exist or not */
CheckMobileNo() {
  debugger;
  this.MobileNo = this.form.controls['mobile'];
  if (this.MobileNo.value.length == 10) {
    var UploadFile = new FormData();
    UploadFile.append("Mobile", this.MobileNo.value);
    UploadFile.append("flag", "1");
    var url = "api/Admin/CheckMobileandEmail"
    debugger;
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger;
      if (data == 'EXIST') {
        this.checkNo = 10;
        this.form.controls['mobile'].setErrors({ 'incorrect': true });
        //this.generalService.ShowAlert('ERROR', 'Mobile number is already used.', 'error')
      }
      else {
        this.checkNo = 11;
      }
    }, err => {

    });
  }
}

/* check email exist or not */
CheckEmail() {
  debugger;
  if (this.form.controls['email'].hasError('invalidEmail') != true) {
    this.Emailid = this.form.controls['email'];
    var UploadFile = new FormData();
    UploadFile.append("Mobile", this.Emailid.value);
    UploadFile.append("flag", "2");
    var url = "api/Admin/CheckMobileandEmail"
    debugger;
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger;
      if (data == 'EXIST') {
        this.checkemail = 10;
        this.form.controls['email'].setErrors({ 'incorrect': true });
        // this.generalService.ShowAlert('ERROR', 'Email is already used.', 'error')
      }
      else {
        this.checkemail = 11;
      }
    }, err => {

    });
  }
}
  getCategories() {
    debugger
    var url = "api/Admin/GetMainCategories";
    this.generalService.GetData(url).then((data: any) => {
      debugger;
      this.category = data;
      if (this.category != null) {
        for (var i = 0; i < this.category.length; i++) {
          this.categories.push({ label: this.category[i].Name, value: this.category[i].ID })

        }
      }
   
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

    });
  }


  getStates() {
    debugger
    var url = "api/Values/GetStatemaster";
    this.generalService.GetData(url).then((data: any) => {
      debugger;
      this.StateNames = data;
      if (this.StateNames != null) {
        for (var i = 0; i < this.StateNames.length; i++) {
          this.States.push({ label: this.StateNames[i].StateName, value: this.StateNames[i].StateId })

        }
      }

    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')

    });
  }
  removeImage(): void {
    debugger
    this.image = '';
    this.IsOutlet = false;
  }

  CancelForm() {
    debugger

  }
  onSubmit(data) {
    debugger
    let arr = [];
    let main = [];
    if (this.imagefile == undefined || this.imagefile == null || this.imagefile == '') {
      this.imagefile = this.img1


    }
    if (this.form.valid) {
      arr.push({
        Name: data.name,
        Mobile: data.mobile,
        Email: data.email,
        GstNo: data.GstNo,
        VendorLogo: this.imagefile,
        Password: data.password,
        //ID: this.Vendorid,
        StateId: data.StateNameid,
        Status: false,
        RoleId: 2,
        StatusId: 1,
        CreatedBy: 2,
        Address: data.address,
        Area: data.area,
        LandMark: data.landmark,
        Pincode: data.pincode,
        City: data.city,
        //StateId: data.StateNameid,
        latitude: "", longitude: ""
      });
      this.Outlets = [];
      this.Outlets.push({

        Outletname: data.outletname,
        Address: data.address,
        Area: data.area,
        LandMark: data.landmark,
        Pincode: data.pincode,
        City: data.city,
        StateId: data.StateNameid,
        latitude: "", longitude: ""
      })

      
      if (data.catType != '' || data.catType != null) {
        for (var i = 0; i < data.catType.length; i++) {
          main.push({ Value: data.catType[i], label: data.catType[i] })
        }
      }
    }

    //var url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + this.Outlets[0].LandMark + this.Outlets[0].Area + this.Outlets[0].City + "&key=AIzaSyDXzfwzxe311XVb7LMT_jhK4pjAx8K4WMk"
    var url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + this.Outlets[0].LandMark + this.Outlets[0].Area + this.Outlets[0].City + "&key=AIzaSyBPFXmwHMaoN_CVZ2K1w2kMLm5qpSXD_s8"

    this.http.get(url).subscribe(Object => {
      debugger
      this.location = Object;
      if (this.location.results.length == 0) {
        alert('Please Enter valid details');
        //this.spinner = false;
      }
      else {

        this.Outlets[0].latitude = this.location.results[0].geometry.location.lat;
        this.Outlets[0].longitude = this.location.results[0].geometry.location.lng;
        arr[0].latitude = this.location.results[0].geometry.location.lat;
        arr[0].longitude = this.location.results[0].geometry.location.lng;

        var UploadFile = new FormData();
        UploadFile.append("VendorDetails", JSON.stringify(arr));
        
        UploadFile.append("VehicleTypeIds", JSON.stringify(main));
        UploadFile.append("VendorOutlets", JSON.stringify(this.Outlets))
        UploadFile.append("flag", "1");
        var url = "api/Admin/VendorRegisteration"
        this.generalService.PostData(url, UploadFile).then(data => {
          debugger;
          if (data == 'SUCCESS') {
            debugger
           // this.spinner = false;
            this.generalService.ShowAlert('SUCCESS', 'Thank you Registering with KISSAN, your account is under verification by admin you will receive notification soon ', 'success');
            //this.SendsmsforVendorRegistration(JSON.stringify(arr))
            var UploadFile = new FormData();
            UploadFile.append("VendorDetails", JSON.stringify(arr));
            UploadFile.append("VehicleTypeIds", JSON.stringify(main));
            UploadFile.append("VendorOutlets", JSON.stringify(this.Outlets));
            var url = "api/Values/RegMailTo_Vendor";
            this.generalService.PostData(url, UploadFile).then(data => {
              var msg = data;
            });
            this.ngOnInit();
            this.form.reset();
            this.Outlets = [];
            this.image = "";
            //this.vehicle = 0;
            this.router.navigateByUrl('/')
          }
          else {
           // this.spinner = false;
            this.generalService.ShowAlert('ERROR', data, 'error')
          }
        }, err => {
          //this.spinner = false;
          this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
      }
    });
  }

  


  keyPress(event: any) {
    debugger;
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
export function emailValidator(control: FormControl): { [key: string]: any } {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
  debugger
  return (group: FormGroup) => {
    let password = group.controls[passwordKey];
    let passwordConfirmation = group.controls[passwordConfirmationKey];
    if (password.value !== passwordConfirmation.value) {
      return passwordConfirmation.setErrors({ mismatchedPasswords: true })
    }
  }
}
