import { Component, OnInit,ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-footer1',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

}
