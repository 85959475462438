import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  Details: any;
  Name: any;
  Mobile: any;
  Email: any;
  checkemail: number;
  checkNo: number; 
  Image: any;
  Area: string = ""; City: string = ""; LandMark: string = ""; Pincode: number; Address: string = "";

  constructor() { }

  ngOnInit() {
    debugger
    this.Details = JSON.parse(localStorage.getItem("UserLoginDetails"));
    //this.Name = this.Details[0].Name;
    this.Name = this.Details.Name;
    this.Mobile = this.Details.Mobile;
    this.Email = this.Details.Email;
    this.Address = this.Details.Address;
    this.Image = this.Details.Image;
    this.Area = this.Details.Area;
    this.LandMark = this.Details.LandMark;
    this.City = this.Details.City;
  }
  CheckEmail(Email) {
    debugger
  }
  CheckMobileNo(Mobile) {
    debugger
  }
  validatepri(event) {
    debugger
  }
  UpdateCustomerData(Name, Email, Mobile, Area, City, State, Landmark, Pincode, Address) {
    debugger
  }

}
