import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, AbstractControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { userservice } from '../userservice';
import { Observable, from } from 'rxjs';
declare var jQuery: any;
declare var $: any;
import { HttpClient } from '@angular/common/http';
import { debug, log } from 'util';
import { id } from '@swimlane/ngx-charts/release/utils';

import { GeneralservicesService } from '../../generalservice';
import { ShowDetailsComponent } from '../show-details/show-details.component';




@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  //providers: [ userservice]

})
export class PageHeaderComponent implements OnInit {
  Loginid: number;
  Logdatalength: any;
  @Input() name: string;
  CatgoryID: string = "0";
  list: any;
  count: any;
  Flag: number = 1;
  HomeUrl: any;
  Logindet: any;
  id: any;
  logindata: any;
  brandslist: any;
  CategoryList: any;
  modelslist: any;
  subcatlist: any;
  subSubCategoriesList: any;
  ProductSizeList: any;
  vehiclelist: any;
  CurID: number = 0;
  data1: any;
  CurrentIndex: any;
  Categories: any;
  CurrVehID: any;
  Subcategories: any;
  CurrCatID: any;
  Item: any;
  ShowProducts: any;
  CurrSubCatId: any;
  SubSubcategories: any;
  Categories1: any;
  Search: string = "";
  MaincategoryList: any;
  showid: any; code: any; Wallet1: any; Wallet: any; spinner: boolean = false;
  totalamount: any;
  constructor(public router: Router, public http: HttpClient, public userservice: userservice,
    public generalservice: GeneralservicesService, public fb: FormBuilder) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
    debugger
    this.count = JSON.parse(localStorage.getItem("totalcartitems"));
    this.logindata = JSON.parse(localStorage.getItem("LoginDetails"));
   // this.Amount = JSON.parse(localStorage.getItem("LoginDetails"));
    //this.Logdatalength = this.logindata.length;
    //sessionStorage.removeItem('ScrollID');
  }

  ngOnInit() {
    this.GetWallet();
    if (this.logindata != null) {
      this.Loginid = this.logindata.RoleID;
      if (this.Loginid == 3) {
        this.showid = true;
      }
       
    }
    debugger
    this.userservice.cast.subscribe(user => this.count = user);
    if (this.count == null || this.count == undefined || this.count == "") {
      this.count = 0;
      this.Flag = 1;
    }
    this.mt();
    this.ProductsId();
  }

  onChange(ev) {
    debugger;
    this.CurID = ev.evalue;
  }

  GetWallet() {
    debugger;
    this.code = this.logindata.ID
    this.spinner = true;
    var url = "/api/Admin/userPromocodeCurd";
    var UploadFile = new FormData();
    UploadFile.append("Flag", '2');
    UploadFile.append("Xml", '');
    UploadFile.append("User", this.code);
    this.generalservice.PostData(url, UploadFile).then((data: any) => {
      debugger;
      this.Wallet = data.List;
      this.Wallet1 = data.List1;
      //this.totalamount = this.Wallet1[0].TotalAmount;
      this.spinner = false;
    }, err => {
      this.generalservice.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      this.spinner = false;
    });
  }



  //SingleFliter(event, CurID) {
  //  debugger
  //  var query = event.query;

  //  //var f = new string(query);
  //  debugger
  //  if (CurID == "" || CurID == undefined || CurID == 0) {
  //    this.generalservice.ShowAlert('alret', 'plz select producttype', 'alret')
  //  }
  //  else {
  //    debugger
  //    if (query.length > 1) {
  //      debugger
  //      var UploadFile = new FormData();
  //      UploadFile.append("ProductName", query);
  //      UploadFile.append("Name", CurID);
  //      var url = 'api/Admin/SearchProductss';
  //      this.generalservice.PostData(url, UploadFile).then(data => {
  //        debugger
  //        this.ShowProducts = data;
  //        if (this.ShowProducts.length == 0) {
  //          alert("No Search Items")
  //        }
  //      });

  //    }
  //    else {
  //      this.ShowProducts = [];
  //    }





  //  }
  //}

  SingleFliter(event) {
    debugger
    var query = event.query;


    debugger

    debugger
    if (query.length > 1) {
      debugger
      var UploadFile = new FormData();
      UploadFile.append("ProductName", query);
      UploadFile.append("Name",'');
      var url = 'api/Admin/SearchProductss';
      this.generalservice.PostData(url, UploadFile).then(data => {
        debugger
        this.ShowProducts = data;
        if (this.ShowProducts.length == 0) {
          alert("No Search Items")
        }
      });

    }
    else {
      this.ShowProducts = [];
    }

  }


  dropdown() {
    $(document).ready(function () {
      $('.dropdown-submenu a.test').on("click", function (e) {
        $(this).next('ul').toggle();
        e.stopPropagation();
        e.preventDefault();
      });
    });
  }
  Search1(CatgoryID, Search) {
    debugger
    if (CatgoryID == 0) {
      this.generalservice.ShowAlert('alret', 'please select productId', 'alret');
    }
    else if (Search == 0) {
      this.generalservice.ShowAlert('alret', 'please select productName', 'alret');
    }
    else {
      let list: any = [];
      list.push({ Product: CatgoryID, Item: Search.ProductName })
      Search[0].MainCategoryID = CatgoryID
      sessionStorage.setItem("Search", JSON.stringify(Search));
      this.router.navigate(['/Home']);
      //this.router.navigateByUrl('/Home ', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/ShowDetails']);
      //});
    }
  }


  login(id) {
    debugger
    localStorage.setItem("log", id)
    this.data1 = localStorage.getItem("log");
    this.router.navigateByUrl('/Home', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/register']);
    });

  }

  LoginPage() {
    localStorage.setItem("RoleId", "3");
    this.router.navigate(['/login'])
  }


  //login(id) {
  //  debugger
  //  //localStorage.removeItem('log');
  //   localStorage.setItem("log", id)
  //  this.data1 = localStorage.getItem("log")
  //  if (id == 1) {
  //    this.router.navigateByUrl('/Home ', { skipLocationChange: true }).then(() => {
  //      this.router.navigate(['/login']);
  //    });
  //  }
  //  else if (id == 2) {
  //    this.router.navigateByUrl('/Home ', { skipLocationChange: true }).then(() => {
  //      this.router.navigate(['/login']);
  //    });
  //  }
  //}



  mt() {
    window.onscroll = function () { myFunction() };
    var header = document.getElementById("myHeader");
    var sticky = header.offsetTop;
    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  }
  ProductsId() {
    debugger
    var url = "api/Admin/GetBrandsCategorysubcategories";

    this.generalservice.GetData(url).then((data: any) => {
      this.data1 = data;
      debugger
      this.brandslist = data.BrandsList;
      this.CategoryList = data.CategoryList;
      // alert(JSON.stringify(data.CategoryList));
      this.subcatlist = data.SubcategoryList;
      this.subSubCategoriesList = data.subSubCategoriesList;
      // this.ProductSizeList = data.ProductSizeList;
      this.brandslist = data.BrandsList;
      this.MaincategoryList = data.MainCategoryList;

    });

    //}
    //filterCategory(ProductsId) {
    //  this.CurID = ProductsId
    //  this.Categories = this.CategoryList.fliter(a => a.productsId == ProductsId);

  }
  filterSubCategory(ID) {
    this.CurID = ID
    this.Subcategories = this.subcatlist.filter(a => a.ID == ID);

  }
  filterSubSubCategory(SubCatID) {
    this.CurrSubCatId = SubCatID
    this.SubSubcategories = this.subSubCategoriesList.filter(a => a.SubCatID = SubCatID);

  }
  AccSingleItem(item) {
    debugger
    item.ID = this.CurID;
    let list: any = [];
    list.push(item);
    //sessionStorage.setItem("Search", JSON.stringify(this.list));
    sessionStorage.setItem("Search", JSON.stringify(item));
    localStorage.removeItem('NewItem');
    this.router.navigateByUrl('/Home', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/ShowDetails']);
    });
  }
  AccSingleItem1(Item) {
    Item.ID = this.CurID;
    let list: any = [];
    list.push(Item);
    //sessionStorage.setItem("ItemData", JSON.stringify(this.list));
    sessionStorage.setItem("ItemData", JSON.stringify(this.list));

    localStorage.removeItem('ItemData');
    this.router.navigateByUrl('/Home', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/ShowDetails']);
    });
  }

  Logout() {
    debugger
    localStorage.removeItem("LoginDetails");
    localStorage.removeItem("totalcartitems");
    localStorage.removeItem("ItemData");
    localStorage.removeItem("Productdetails");
    this.router.navigateByUrl('/Home');
    location.reload();
    this.Loginid = 3;
  }


  getcartitems(count) {
    debugger
    //  this.count = count;
    this.count = JSON.parse(localStorage.getItem('totalcartitems'));
  }

  /*show add to cart page*/
  ShowAddCartPage() {
    debugger
    this.count = JSON.parse(localStorage.getItem('totalcartitems'));
    if (this.count == null) {
      this.generalservice.ShowAlert('alret', 'No items in your cart', 'alret')
    }
    else {
      this.router.navigateByUrl('/Addtocart');

    }
  }
  /*end code*/

  Scroll(ID) {
    debugger
    sessionStorage.removeItem('ScrollID');
    sessionStorage.setItem('ScrollID', ID);
    this.router.navigateByUrl('/Addtocart', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/Home']);
    });
    // this.router.navigateByUrl('/Home');
  }
}

