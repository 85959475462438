import { Component, OnInit, ViewEncapsulation, NgZone, ViewChild, ElementRef, } from '@angular/core';
import { Router, provideRoutes } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { GeneralservicesService } from '../../generalservice';
import { PageHeaderComponent } from '../page-header/page-header.component'
import { debounce } from 'rxjs/operators';
import { userservice } from '../userservice'
import { log } from 'util';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { google } from '@agm/core/services/google-maps-types';
declare var Razorpay: any; // Declare Razorpay variable
declare var $: any

@Component({
  selector: 'app-addtocart',
  templateUrl: './addtocart.component.html',
  styleUrls: ['./addtocart.component.scss'],
  providers: [PageHeaderComponent]
})
export class AddtocartComponent implements OnInit {
  CheckRewards: boolean = false;
  OrderNo: any;
  products: any = [];
  spinner1: any;
  HomeUrl: any;
  address: string;
  LD: any;
  google: any;
  totalcartitems: any;
  totalPrice: number = 0;
  gst: number;
  cartProducts: any[];
  Logindetails: any;
  RoleId: any;
  GstPrice: any;
  totalPrice1: any;
  selectedValue: any;
  ProductList: any;
  whatsAppProductURL: string;
  location: any;
  Quantity: any = 1;
  Paymentbutton: boolean = true;
  public form: FormGroup
  public Pincode: AbstractControl
  public LandMark: AbstractControl;
  public Address: AbstractControl;
  public Area: AbstractControl;
  public District: AbstractControl;
  public Mandal: AbstractControl;
  public Village: AbstractControl;
  GstPercentage: number;
  UserRewards: any;
  DistrictsList: any;
  MandalsList: any;
  VillagesList: any;
  MandalsAryList: any;
  VillagesAryList: any;
  DistrictsList1: any[];
  MandalsList1: any[];
  VillagesList1: any[];
  arr1: any[];
  ProductId: any;
  promo: any;
  code: any;
  Flag: any = 1;
  lat: number;
  long: number;
  zoom: number;
  resultset = [];
    AddressDetails: Object;
  map: boolean
  // @ViewChild('search')
  public searchElementRef: ElementRef;
  // @ViewChild('search1')
  public search1ElementRef: ElementRef;
  autocomplete;
  AddressObj = {
    country: "", postal_code: "", administrative_area_level_1: "", administrative_area_level_2: "",
    locality: "", sublocality_level_1: "", sublocality_level_2: "", sublocality_level_3: "", neighborhood: "",
    premise: ""
  };
  shortNameCountry: any;
  Adminprice: any;
  Dropaddress: any;
  searchType: string;
  Droplatitude: number;
  Droplongitude: number;
  longitude: number;
  latitude: number;
  geoCoder: any;
  GstTotal: any;
  rzpoption: any;
  constructor(private mapsAPILoader: MapsAPILoader, public ngZone: NgZone, public userservice: userservice, public router: Router, fb: FormBuilder, public formBuilder: FormBuilder,

    public http: HttpClient, public generalService: GeneralservicesService, public header: PageHeaderComponent) {
    debugger
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });

    this.form = fb.group({
      Pincode: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])],
      LandMark: ['', Validators.required],
      // number: ['', CustomValidators.number],
      Address: ['', Validators.required],
      //Area: ['', Validators.required],
      District: ['', Validators.required],
      Mandal: ['', Validators.required],
      Village: ['', Validators.required],




    });

    this.Pincode = this.form.controls['Pincode'];

    this.Address = this.form.controls['Address'];
    this.Village = this.form.controls['Village'];
    this.District = this.form.controls['District'];
    this.Mandal = this.form.controls['Mandal'];

    this.LandMark = this.form.controls['LandMark'];

    this.selectedValue = "Online";
    this.products = JSON.parse(localStorage.getItem('Productdetails'));
    this.Logindetails = JSON.parse(localStorage.getItem('LoginDetails'));
    if (this.Logindetails != null) {
      this.RoleId = this.Logindetails.RoleID;
      this.GetUserRewardPoints();
    }
    // this.GetUserRewardPoints(this.Logindetails);

  }

  ngOnInit() {
    this.getProducts();
    this.GetDistricts();
    this.GetMandals();
    this.GetVillages();  
    this.setCurrentLocation();
  }

  // Get Current Location Coordinates
  ///get current location commented by srinivas
  private setCurrentLocation() {
    debugger
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude;
        this.zoom = 12;
        this.getAddress(this.lat, this.long);
      });
    }
  }

  markerDragEnd($event: any) {
    debugger
    this.lat = $event.coords.lat;
    this.long = $event.coords.lng;
    this.getAddress(this.lat, this.long);
  }

  getAddress(latitude: number, longitude: number) {
    debugger
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.address = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      });
    });
  }











  //private setCurrentLocation() {
  //  if ('geolocation' in navigator) {
  //    navigator.geolocation.getCurrentPosition((position) => {
  //      this.lat = position.coords.latitude;
  //      this.long = position.coords.longitude;
  //      this.zoom = 8;
  //      this.getAddressmaps(this.lat, this.long);
  //    });
  //  }
  //}

  //markerDragEnd($event: MouseEvent) {
  //  //console.log($event);
  //  this.lat = $event.coords.lat;
  //  this.long = $event.coords.lng;
  //  this.getAddressmaps(this.lat, this.long);
  //}

  //getAddressmaps(latitude, longitude) {
  //  this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
  //    this.resultset = results;
  //    if (status === 'OK') {
  //      if (results[0]) {
  //        console.log(results);
  //        this.zoom = 12;
  //        this.address = results[0].formatted_address;
  //      } else {
  //        window.alert('No results found');
  //      }
  //    } else {
  //      window.alert('Geocoder failed due to: ' + status);
  //    }

  //    var address_components = this.resultset[0].address_components;
  //    this.AddressObj = {
  //      country: "", postal_code: "", administrative_area_level_1: "", administrative_area_level_2: "",
  //      locality: "", sublocality_level_1: "", sublocality_level_2: "", sublocality_level_3: "", neighborhood: "",
  //      premise: ""
  //    };

  //    for (var i = 0; i < address_components.length; i++) {
  //      for (var j = 0; j < address_components[i].types.length; j++) {
  //        var temp = address_components[i].types[j];
  //        switch (temp) {
  //          case "country": {
  //            this.AddressObj.country = address_components[i].long_name;
  //            this.shortNameCountry = address_components[i].short_name;
  //            break;
  //          }
  //          case "postal_code": {
  //            this.AddressObj.postal_code = address_components[i].long_name;
  //            break;
  //          }
  //          case "administrative_area_level_1": {
  //            this.AddressObj.administrative_area_level_1 = address_components[i].long_name;
  //            break;
  //          }
  //          case "administrative_area_level_2": {
  //            this.AddressObj.administrative_area_level_2 = address_components[i].long_name;
  //            break;
  //          }
  //          case "locality": {
  //            this.AddressObj.locality = address_components[i].long_name;
  //            break;
  //          }
  //          case "sublocality_level_1": {
  //            this.AddressObj.sublocality_level_1 = address_components[i].long_name;
  //            break;
  //          }
  //          case "sublocality_level_2": {
  //            this.AddressObj.sublocality_level_2 = address_components[i].long_name;
  //            break;
  //          }
  //          case "sublocality_level_3": {
  //            this.AddressObj.sublocality_level_3 = address_components[i].long_name;
  //            break;
  //          }
  //          case "neighborhood": {
  //            this.AddressObj.neighborhood = address_components[i].long_name;
  //            break;
  //          }
  //          case "premise": {
  //            this.AddressObj.premise = address_components[i].long_name;
  //            break;
  //          }
  //        }

  //      }
  //    }
  //    this.AddressDetails = this.AddressObj
  //  });
  //}

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

  getProducts() {
    debugger
    this.cartProducts = [];
    for (var i = 0; i < this.products.length; i++) {
      var gst = this.products[i].GST / 100;
      this.totalPrice = parseFloat(this.products[i].AdminPrice1) * 1;
      this.GstPrice = gst * this.totalPrice;
      var baseprice = this.totalPrice - parseFloat(this.GstPrice);
      this.cartProducts.push({
        ID: this.products[i].ID, ProductID: this.products[i].ProductID, ProductName: this.products[i].ProductName,
        ProductImages: this.products[i].ProductImages, AdminPrice: this.products[i].AdminPrice, AgentPrice: this.products[i].AgentPrice,
        DealerPrice: this.products[i].DealerPrice, VendorPrice: this.products[i].VendorPrice, MinimumQuantity: this.products[i].MinimumQuantity,
        Quantity: 1, BrandName: this.products[i].BrandName, SubCategoryName: this.products[i].SubCategoryName, SSName: this.products[i].SSName,
        CategoryName: this.products[i].CategoryName, SizeID: this.products[i].SizeID, Size: this.products[i].Size, BookingStatus: this.products[i].BookingStatus, Advanceprice: this.products

        [i].Advanceprice,
        GST: this.products[i].GST, GstPrice: this.GstPrice, AdminPrice1: this.products[i].AdminPrice1
        , BasePrice: baseprice
      })
    }
    console.log(this.RoleId);
    console.log(this.cartProducts);
    //localStorage6.setItem('Productdetails', JSON.stringify(this.cartProducts));
    this.GetTotalPrice();
  }

  GetUserRewardPoints() {
    debugger;
    var url = "/api/Admin/GetRewardPointsforuser";
    var UploadFile = new FormData();
    UploadFile.append("UserID", this.Logindetails.ID);
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger;
      this.UserRewards = data;
    });
  }


  Qty(index, Qty) {
    debugger
    this.cartProducts[index].Quantity = parseInt(Qty);
    this.GetTotalPrice();
  }


  changequantity(index, type) {
    debugger
    if (type == 1) {
      this.cartProducts[index].Quantity = parseInt(this.cartProducts[index].Quantity) + 1;
    }
    else if (type == 2) {
      this.cartProducts[index].Quantity = parseInt(this.cartProducts[index].Quantity) - 1;
    }
    this.GetTotalPrice();
    // localStorage.setItem('Productdetails', JSON.stringify(this.cartProducts));
  }

  GetTotalPrice() {
    debugger
    this.totalPrice = 0;
    this.GstPercentage = 0;
    var gst = 0, total = 0;
    if (this.RoleId == 3) {
      for (var i = 0; i < this.cartProducts.length; i++) {
        var price = 0;
        if (this.cartProducts[i].AdminPrice != undefined && this.cartProducts[i].AdminPrice != null && this.cartProducts[i].AdminPrice != '') {
          price = parseInt(this.cartProducts[i].AdminPrice) * parseInt(this.cartProducts[i].Quantity);
          this.GstPercentage = this.GstPercentage + this.cartProducts[i].GST;
          var gst = this.cartProducts[i].GST / 100;
          this.totalPrice = this.totalPrice + price;
          this.GstPrice = gst * this.totalPrice
          this.totalPrice1 = this.totalPrice + this.GstPrice;
          this.Adminprice = this.cartProducts[i].AdminPrice + this.GstPrice
        } else {
          price = parseFloat(this.cartProducts[i].AdminPrice1) * 1;
          this.totalPrice = this.totalPrice + price;
          this.GstPrice = this.cartProducts[i].GstPrice;
        }
        gst = gst + this.GstPrice;
        total = this.totalPrice - gst;
      }
      this.GstTotal = gst.toFixed(3);
      this.totalPrice1 = total.toFixed(3);
      //if (this.CheckRewards == false) {
      //  this.totalPrice1 = this.totalPrice1 + Number(this.UserRewards);
      //}
      //else {
      //  this.totalPrice1 = this.totalPrice1 - this.UserRewards;
      //}
    }
    else if (this.RoleId == 4) {
      for (var i = 0; i <= this.cartProducts.length; i++) {
        var price = 0;
        if (this.cartProducts[i].DealerPrice != undefined && this.cartProducts[i].AdminPrice != null && this.cartProducts[i].AdminPrice != '') {

          price = parseInt(this.cartProducts[i].DealerPrice) * parseInt(this.cartProducts[i].Quantity);
          this.totalPrice = this.totalPrice + price;
          this.GstPrice = 0.18 * this.totalPrice
          this.totalPrice1 = this.totalPrice + this.GstPrice;
        }
        this.GstPrice = this.GstPrice.toFixed(2);
        this.totalPrice1 = this.totalPrice1.toFixed(2);
      }

    }
    else {

      for (var i = 0; i <= this.cartProducts.length; i++) {
        var price = 0;
        if (this.cartProducts[i].AdminPrice != undefined && this.cartProducts[i].AdminPrice != null && this.cartProducts[i].AdminPrice != '') {
          price = parseInt(this.cartProducts[i].AdminPrice) * parseInt(this.cartProducts[i].Quantity);
          this.GstPercentage = this.GstPercentage + this.cartProducts[i].GST;
          var gst = this.cartProducts[i].GST / 100;
          this.totalPrice = this.totalPrice + price;
          this.GstPrice = gst * this.totalPrice
          this.totalPrice1 = this.totalPrice + this.GstPrice;
        }
        this.GstPrice = this.GstPrice.toFixed(2);
        this.totalPrice1 = this.totalPrice1.toFixed(2);
      }

    }


  }
  FilterMandals(val) {
    debugger
    let query = val.DistrictID;
    this.MandalsAryList = this.MandalsList.filter(t => t.DistrictID == query);

  }
  //FilterDistrict(val) {
  //  debugger
  //  let query = val.StateId;
  //  this.district1 = this.district.filter(t => t.StateId == query);

  //}

  FilterVillagesList(val) {
    debugger
    let ftp = val.MandalID;
    this.VillagesAryList = this.VillagesList.filter(t => t.MandalID == ftp);
  }
  RemoveItems(i: any, value: any) {
    debugger
    this.products.splice(i, 1);
    this.cartProducts.splice(i, 1);
    //this.addtocartlist.splice(i, 1);
    localStorage.setItem("Productdetails", JSON.stringify(this.products));
    this.totalcartitems = localStorage.setItem("totalcartitems", JSON.stringify(this.products.length));
    this.header.getcartitems(this.products.length);
    this.userservice.edituser(this.products.length);
    this.generalService.ShowAlert('SUCCESS', "Item removed from Cart Successfully", 'success');
    if (this.cartProducts.length != 0) {
      this.GetTotalPrice();
    }
    else {
      this.totalPrice1 = 0;
      this.GstPercentage = 0;
      this.totalPrice = 0;
      this.GstPrice = 0;
    }
  }
  Continue() {
    this.router.navigateByUrl('/ShowDetails');
    $('#myModal1').modal('hide');
  }
  Closing() {
    debugger

    this.router.navigate(['/myorders']);
    $('#myModal1').modal('hide');

  }

  CheckOut() {
    debugger
    localStorage.setItem('cartProducts', JSON.stringify(this.cartProducts));
    if (this.RoleId != null) {
      // this.router.navigateByUrl('/checkout');
      $('#myModal').modal('show');
    }
    else {
      localStorage.setItem("RoleId", "3");
      localStorage.setItem("checkout", 'true');
      this.router.navigate(['/login'])
      this.generalService.ShowAlert('INFO', 'Please login to checkout your order.', 'info');
    }
  }

  /*use the reward points when click on check box*/
  InsertRewards(ev) {
    debugger
    this.CheckRewards = ev;
    this.GetTotalPrice();
  }
  /*end code*/
  GetDistricts() {
    debugger
    var Url = '/api/Admin/GetDistrict';
    this.generalService.GetData(Url).then((data: any) => {
      debugger
      this.DistrictsList = data;
    });
  }
  GetMandals() {
    debugger
    var Url = '/api/Admin/GetMandal';
    this.generalService.GetData(Url).then((data: any) => {
      debugger
      this.MandalsList = data;
    });
  }
  GetVillages() {
    debugger
    var Url = '/api/Admin/GetVillage';
    this.generalService.GetData(Url).then((data: any) => {
      debugger
      this.VillagesList = data;
    });
  }
  searchDistricts(event) {
    debugger
    let filtered: any[] = [];
    let query = event.query;
    this.DistrictsList1 = [];
    for (let i = 0; i < this.DistrictsList.length; i++) {
      let country = this.DistrictsList[i];
      if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.DistrictsList1 = filtered;
    //this.DistrictsList = [];
  }
  searchMandals(event) {
    debugger
    let filtered: any[] = [];
    let query = event.query;
    this.MandalsList1 = [];
    for (let i = 0; i < this.MandalsAryList.length; i++) {
      let country = this.MandalsAryList[i];
      if (country.MandalName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.MandalsList1 = filtered;
  }
  searchVillages(event) {
    debugger
    let filtered: any[] = [];
    let query = event.query;
    this.VillagesList1 = [];
    for (let i = 0; i < this.VillagesAryList.length; i++) {
      let country = this.VillagesAryList[i];
      if (country.VillageName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.VillagesList1 = filtered;
  }


  showmodal() {
    $('#myModal1').modal('show');



    debugger


    this.code = this.products[0].ProductID
    var UploadFile = new FormData();
    UploadFile.append("ProdID", this.code);
    var url = "api/Admin/ApplyPromocodes";
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      this.promo = data;

      if (this.promo.length > 0) {


        $('#myModal1').modal('show');
        this.AddGroup();
      }
      else {
        this.generalService.ShowAlert('SUCCESS', "Order placed successfully", 'success')
        //window.location.reload();
        this.Closing();
      }
      debugger
    }, err => {
      debugger
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
    });


  }
  AddGroup() {
    debugger;
    var Message: any
    let arr = [];
    arr.push({

      UserId: this.Logindetails.ID,
      PromoCodeId: this.promo[0].ID,
      CreatedBy: this.Logindetails.ID,
    })


    var UploadFile = new FormData();
    UploadFile.append("Flag", this.Flag);
    UploadFile.append("Xml", JSON.stringify(arr));
    UploadFile.append("User", this.Logindetails.ID);

    var url = "/api/Admin/userPromocodeCurd"
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger;
      if (data == 'SUCCESS') {


        //this.generalService.ShowAlert('SUCCESS', "Product booked Successfully", 'success')
      }


    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

  PalceOrder(form, selectedValue) {
    debugger;
    //this.Paymentbutton = false;
    this.ProductList = [];
    //this.cartProducts[0].City = form.City;
    //this.cartProducts[0].Address = form.Address;
    //this.cartProducts[0].Pincode = form.Pincode;
    //this.cartProducts[0].Area = form.Area;
    //this.cartProducts[0].LandMark = form.LandMark;//https://maps.googleapis.com/maps/api/js?key=AIzaSyBPFXmwHMaoN_CVZ2K1w2kMLm5qpSXD_s8

    var d = new Date();
    var n = d.getTime();
    if (form.Address && form.Pincode) {
      var url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + form.Address + form.Pincode + "&key=AIzaSyBPFXmwHMaoN_CVZ2K1w2kMLm5qpSXD_s8"
      //var url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + form.Address + form.Pincode + "&key=AIzaSyDXzfwzxe311XVb7LMT_jhK4pjAx8K4WMk"
      this.http.get(url).subscribe(data => {
        this.location = data;
        // alert(JSON.stringify(data));
        debugger
        if (this.location.results.length == 0) {
          alert('Please Enter valid details')
          //this.Paymentbutton = true;
          this.spinner1 = false;
        }
        else {
          var latitude = this.location.results[0].geometry.location.lat;
          var longitude = this.location.results[0].geometry.location.lng;
          this.lat = latitude;
          this.long = longitude;
          for (var i = 0; i < this.cartProducts.length; i++) {
            var productgst = 0;
            var gst = this.cartProducts[i].GST / 100;
            productgst = gst * this.cartProducts[i].AdminPrice;
            if (this.cartProducts[i].BookingStatus == 1) {
              debugger
              this.ProductList.push(
                {
                  NAME: this.Logindetails.Name,
                  MOBILE: this.Logindetails.Mobile,
                  RegID: this.Logindetails.ID,
                  Quantity: this.cartProducts[i].Quantity,
                  SizeID: this.cartProducts[i].SizeID,
                  //City: form.City,
                  Pincode: form.Pincode,
                  Address: form.Address,
                  LandMark: form.LandMark,
                  VillageID: form.Village.VillageID,
                  //Area: form.Area,
                  TotalAmount: this.totalPrice1,
                  TransactionID: n,
                  Latitude: latitude,
                  Longitude: longitude,
                  PricePerQuantity: this.cartProducts[i].AdminPrice,
                  TotalQuantityPrice: this.cartProducts[i].Quantity * (this.cartProducts[i].AdminPrice),
                  TotalProducts: this.cartProducts.length,
                  GstPerProduct: productgst,
                  BookingType: "Advanced"

                }
              )
            }
            else {
              debugger
              this.ProductList.push(
                {
                  NAME: this.Logindetails.Name,
                  MOBILE: this.Logindetails.Mobile,
                  RegID: this.Logindetails.ID,
                  Quantity: this.cartProducts[i].Quantity,
                  //SizeID: this.cartProducts[i].ProductID,// Commented by Ravi Teja. Replaced this with below line.
                  SizeID: this.cartProducts[i].SizeID,
                  //City: form.City,
                  Pincode: form.Pincode,
                  Address: form.Address,
                  LandMark: form.LandMark,
                  //Area: form.Area,
                  VillageID: form.Village.VillageID,
                  TotalAmount: this.totalPrice1,
                  PricePerQuantity: this.cartProducts[i].AdminPrice,
                  TotalQuantityPrice: this.cartProducts[i].Quantity * (this.cartProducts[i].AdminPrice),
                  TotalProducts: this.cartProducts.length,
                  TransactionID: n,
                  Latitude: latitude,
                  Longitude: longitude,
                  GstPerProduct: productgst,
                  BookingType: "Normal"

                })
            }
          }
          debugger
          var UploadFile = new FormData();
          UploadFile.append("OrderXml", JSON.stringify(this.ProductList));
          var d = new Date();
          var n = d.getTime();
          var url = "api/Admin/Insert_CustomerOrders"
          this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data.MSG == "SUCCESS") {
              this.OrderNo = data.OrderID;
              if (selectedValue == "Online") {
                this.initiatePayment();

                //var PaymentDetails = this.HomeUrl + '/api/Admin/Kissan_Payment?NAME=' + this.Logindetails.Name +
                //  "&Amount=" + this.totalPrice1 + "&Email=" + this.Logindetails.Email + "&Productinfo=" + "Product" +
                //  "&Mobile=" + this.Logindetails.Mobile + "&TransactionId=" + n + "&latitude=" + this.lat + "&longitude=" + this.long + "&Orderid=" + data.OrderID;
               // this.http.get(PaymentDetails).subscribe(data => {
                  debugger

                  
                  localStorage.removeItem('totalcartitems');
                  localStorage.removeItem('CartItem');
                  localStorage.removeItem('TotalPrices');
                localStorage.removeItem('Productdetails');
                //window.location.reload();
                  //window.location.href = data.toString()
                this.userservice.edituser(0);
                this.router.navigate(['/myorders']).then(() => {
                  window.location.reload
                });
                //})
              }
              else if (selectedValue == "Offline") {
                debugger
                var url = this.HomeUrl + '/api/Values/ReturnSuccessAfterPaymentMobile?txnid=' + n + "&Productinfo=" + "Product" + "&NAME=" + this.Logindetails.Name +
                  "&Amount=" + this.totalPrice1 + "&Email=" + this.Logindetails.Email + "&Mobile=" + this.Logindetails.Mobile + "&latitude=" + this.lat + "&longitude=" + this.long +

                  "&PaymentStatus=" + "success" + "&paymentType=" + "cod" + "&OrderId=" + data.OrderID;
                this.http.get(url).subscribe((data: any) => {
                  debugger
                  if (data.Table[0].MSG == "SUCCESS") {
                    debugger
                    this.generalService.ShowAlert('SUCCESS', "Product booked Successfully", 'success')
                    $('#myModal').modal('hide');
                    this.showmodal();
                    localStorage.removeItem('totalcartitems');
                    localStorage.removeItem('CartItem');
                    localStorage.removeItem('TotalPrices');
                    localStorage.removeItem('Productdetails')
                    this.userservice.edituser(0);
                    $('#myModal').modal('hide');
                    // this.router.navigateByUrl('/myorders');

                    //$('#myModal1').modal('show');
                    //this.Paymentbutton = false;

                    //this.generalService.ShowAlert('ERROR', 'In your area Agents unavilable', 'error')
                  }
                });
              }

            }
            //else {
            //  this.Paymentbutton = true;

            //  this.generalService.ShowAlert('ERROR', 'In your area Agents unavilable', 'error')
            //}
          });
        }
      });
    }
  }

  financial(x: any) {
    return Number.parseFloat(x).toFixed(2);
  }

  initiatePayment() {
    debugger
    var d = new Date(),
      n = d.getTime();
    var R = this.financial(1);  //TotalPrice
    var S = +R;
    var Price_amount = 1 * 100;
    var p = Price_amount.toFixed();
    // Create a Razorpay payment object with required options
    const options = {
      key: 'rzp_live_LnpQ4EQWubWJOo',
      amount: p,
      description: 'Kisan',
      image: 'https://agrobin.in/webServices/Images/Logo.png',
      //order_id: 'order_Cp10EhSaf7wLbS',//Order ID generated in Step 1
      currency: 'INR',
      // name: 'Acme Corp',
      payment_id: n,
      payment_capture: '1',
            //amount: p,
      handler: (response: any) => {
        // Handle the payment response
        console.log(response);
        // Make an HTTP request to your backend to verify payment and perform further actions
        this.http.post<any>('YOUR_BACKEND_URL', response)
          .subscribe((data) => {
            console.log(data);
            // Perform actions based on payment status
          });
      },
      prefill: {
        name: 'John Doe',
        email: 'john@example.com',
        contact: '9191919191'
      },
      theme: {
        color: '#3399cc'
      }
    };

    const rzp = new Razorpay(options);
    // Create Razorpay instance
    $('#myModal').modal('hide');
    this.showmodal();
    this.rzpoption = rzp
    //window.location.reload();
    rzp.open(); // Open Razorpay payment modal
  }





}
