import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-returnpolicy',
  templateUrl: './returnpolicy.component.html',
  styleUrls: ['./returnpolicy.component.scss']
})
export class ReturnpolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.toTop();
  }
  toTop() {
    //debugger
    window.scroll(0, 0);
  }
  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }
}
