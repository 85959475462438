
import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router, provideRoutes } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GeneralservicesService } from '../../generalservice';
import {  FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { userservice } from '../userservice';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Local } from 'protractor/built/driverProviders';
//import * as _ from 'underscore';
//import { PagerService } from '../../../../src;
declare const $: any;
//@Pipe({
//  name: 'unique',
//  pure: false
//})

@Component({
  selector: 'app-show-details',
  templateUrl: './show-details.component.html',
  styleUrls: ['./show-details.component.scss']
})
export class ShowDetailsComponent implements OnInit {
  AllProducts: any;
  AdvNor: any = [];
  commonProductList: any = [];
  HomeUrl: any;
  ID: any;
  brandslist: any;
  categorylist: any;
  modelslist: any; search: any;
  subcatlist: any;
  subSubCategoriesList: any;
  showsubsubcategorylist: any;
  ProductSizeList: any;
  vehiclelist: any;
  ProductID: number = 0;
  data1: any;
  CurrentIndex: any;
  Categories: any;
  CurrVehID: any;
  Subcategories: any;
  CurrCatID: any;
  Item: any;
  ShowProducts: any;
  CurrSubCatId: any;
  SubSubcategories: any;
  Categories1: any;
  list: any;
  InputName: any;
  spinner: any;
  ShowProductsData: any;
  products: any;
  ShowBrandList: any;
  ShowCategoryList: any;
  ShowSubCategory: any;
  public allItems: any[];
  radioselected: any;
  categorytype: any;
  CategoryId: any;
  LatestItem: any;
  ShowSubCategoryList: any;
  ShowModelList: any;
  ShowModels: any;
  Name: any;
  Home: any;
  searchdata: any;
  ShowProductsData1: any;
  BrandID: any;
  CategoryID: any;
  SubCatID: any;
  searchText: any;
  searchText2: any;
  subsubcatlist: any;
  showsubsubCategory: boolean = false;
  ShowBrands: any;
  searchText3: any;
  count: any;
  productPrice1: any;
  jason: any;
  filter: any;
    SSID: any;
    AddedItems: any[];
  AddtocartItems: any[];
  totalcartitems: any;
  user: any;
  logindata: any;
  product: any;
  item: any;
  Loginid: any;
  MainCategoryList: any;
  ShowMaincategoryList:any
    Maincatid: any;
    SearchItem: any;
    FilteredAdvNormalProducts:any;
    BookingStatusid: any;
  Groups: any;
  groupid: any;
  searchText1: any;
  
  
  constructor( public generalservice: GeneralservicesService, public http: HttpClient
    , public router: Router, public userservice: userservice) {
    debugger
    this.list = [];
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
    debugger
    this.item = JSON.parse(localStorage.getItem('Home'));
    this.logindata = JSON.parse(localStorage.getItem("LoginDetails"));
    if (this.logindata != null) {
      this.Loginid = this.logindata.RoleID;
    }
    this.commonProductList = [];
    //this.GetAllProducts();




  }


  //transform(value: any): any {
  //  if (value !== undefined && value !== null) {
  //    return _.uniqBy(value, 'ProductName');
  //  }
  //  return value;
  //}

  ngOnInit() {  
    debugger
    this.GetAllProducts();
    this.commonProductList = [];
    this.ProductsId();
    
    window.scrollTo(0, 0);
    this.AdvNor = [];
    this.AdvNor.push({ ID: 1, Name: 'Advanced' }, { ID: 2, Name: 'Normal' })
  }
  //ngAfterViewInit() {
  //  document.getElementById('preloder').classList.add('hide');
  //}
 
  ///////////////GetAllBrandCategoryAndSubcatAndSubSubcat
  ProductsId() {
    debugger
    this.spinner = true;
   var url = 'api/Admin/GetBrandsCategorysubcategories';
    this.generalservice.GetData(url).then((data: any) => {
      debugger
      this.categorylist = data.CategoryList;
      this.subcatlist = data.SubcategoryList;
      this.subSubCategoriesList = data.subSubCategoriesList;
      this.brandslist = data.BrandsList;
      this.ProductSizeList = data.ProductSizeList;
      this.MainCategoryList = data.MainCategoryList;
      this.Groups = data.groups;
      console.log(this.MainCategoryList);
      console.log(this.categorylist);

      this.spinner = false;
      this.GetAllProducts();
    }, err => {
      this.generalservice.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });


    
  }

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }
  GetAllProducts() {
    debugger
    this.spinner = true;
    var url = 'api/Admin/ProductsDetails';
    this.generalservice.GetData(url).then((data: any) => {
      debugger
      this.spinner = false;
      this.products = data;
      this.AllProducts = data;            //taking for Advance and Normal prods
      this.ShowProductsData = [];
      this.FilteredAdvNormalProducts = [];
      //for (var i = 0; i < this.products.length; i++) {
      //  this.products[i].Description = decodeURIComponent(this.products[i].Description);       
      //}
      debugger
      this.Name = 1;
      var HeaderItem = JSON.parse(sessionStorage.getItem('ItemData'));
      var LatestItem = JSON.parse(localStorage.getItem('NewItem'));
      this.SearchItem = [];
      var searchitem1 = JSON.parse(sessionStorage.getItem('Search'));
      var HomeItem = JSON.parse(sessionStorage.getItem('Home'));
      this.SearchItem.push(searchitem1);
      sessionStorage.removeItem('Home');
      sessionStorage.removeItem('Search');
    sessionStorage.removeItem('ItemData');
     localStorage.removeItem('NewItem');

      if (searchitem1 != null) {
        debugger
        this.commonProductList = [];
        this.radioselected = searchitem1.MaincategoryID;
        this.groupid = searchitem1.GroupID;
        for (var i = 0; i < this.brandslist.length; i++) {
          debugger
          if (this.brandslist[i].BrandID == searchitem1.BrandID) {
            debugger;
            this.brandslist[i].checked = true
          }
          else {
            this.brandslist[i].checked = false
          }

        }
        this.commonProductList.push({ CategoryID: 0, BrandID: 0, SubCatID: 0, SSID: 0, MaincategoryID: searchitem1.MaincategoryID, GroupID: this.groupid })
        this.commonProductList.push({ CategoryID: 0, BrandID: searchitem1.BrandID, SubCatID: 0, SSID: 0, MaincategoryID:0, GroupID:0 })

      }
      else if (HomeItem != null)
      {
        this.radioselected = HomeItem
        this.commonProductList.push({ CategoryID: 0, BrandID: 0, SubCatID: 0, SSID: 0, MaincategoryID: HomeItem, GroupID: 0})
      }
      else {
        
        this.radioselected = 1;
        this.commonProductList.push({ CategoryID: 0, BrandID: 0, SubCatID: 0, SSID: 0, MaincategoryID: 1, GroupID: 0 })
       

      }
      this.Maincatid = this.radioselected;

      /*show categories based on main categoryid*/
      this.ShowCategoryList = [];
      this.ShowCategoryList = this.categorylist.filter((a => a.MainCategoryID == this.Maincatid))

    /*show sub categories based on show category list*/
      this.ShowSubCategoryList = []

      if (this.ShowCategoryList.length > 0) {
        for (var i = 0; i < this.ShowCategoryList.length; i++) {
          for (var j = 0; j < this.subcatlist.length; j++) {
            if (this.ShowCategoryList[i].ID == this.subcatlist[j].SubCatID) {
              this.ShowSubCategoryList.push(this.subcatlist[j]);
            }
          }

        }
      }
      debugger;

    /*show sub categories based on show category list*/
      this.showsubsubcategorylist = [];

      if (this.ShowSubCategoryList.length > 0) {
        for (var i = 0; i < this.ShowCategoryList.length; i++) {
          for (var j = 0; j < this.subSubCategoriesList.length; j++) {
            if (this.ShowCategoryList[i].ID == this.subSubCategoriesList[j].SubCatID) {
              this.showsubsubcategorylist.push(this.subSubCategoriesList[j]);
            }
          }
        }
      }

      var url = "/api/Admin/FilterProducts"
      var UploadFile = new FormData();
      UploadFile.append("Products", JSON.stringify(this.commonProductList));

      this.generalservice.PostData(url, UploadFile).then((data: any) => {
        debugger;
        //alert(JSON.stringify(data));
        this.FilteredAdvNormalProducts = [];
        this.FilteredAdvNormalProducts = data;
        this.ShowProductsData = data;

      });

    })

   

  }
  



    
//////////// filter method based on database commented by Anil ///////////


  Category(item, ID) {
    debugger; 
    if (item.target.checked == true) {
      this.commonProductList.push({ CategoryID: ID, BrandID: 0, SubCatID: 0, SSID: 0, MaincategoryID: 0, GroupID: 0 })
    }
    else {
      var index1 = this.commonProductList.findIndex(x => x.CategoryID === ID);
      if (index1 > -1) {
        this.commonProductList.splice(index1, 1);
      }
    }
    //this.ShowMaincategoryList = [];
    this.ShowSubCategoryList=[]
    for (var i = 0; i < this.commonProductList.length; i++) {
      for (var j = 0; j < this.subcatlist.length; j++) {
        if (this.commonProductList[i].CategoryID == this.subcatlist[j].ID) {
          this.ShowSubCategoryList.push(this.subcatlist[j]);
        }
      }

    }

   
    var url = "/api/Admin/FilterProducts"
    var UploadFile = new FormData();
    UploadFile.append("Products", JSON.stringify(this.commonProductList));

    this.generalservice.PostData(url, UploadFile).then((data: any) => {
      debugger;
      //alert(JSON.stringify(data));
      this.FilteredAdvNormalProducts = [];
      this.FilteredAdvNormalProducts = data;
     
      if (this.BookingStatusid == 1) {
        this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a=>a.BookingStatus==1);
      }
      else if ((this.BookingStatusid == 2)) {
        this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == 2);
      }
      else {
        this.ShowProductsData = data;
      }

      //if (this.ShowProductsData.length == 0) {

      //  var data = this.products.filter((a => a.MainCategoryID == this.Maincatid))
      //  this.ShowProductsData = data;
      //}

    });
 
  }



      
  subcategory(item, SubCatID) {
    debugger;


    if (item.target.checked == true) {
      this.commonProductList.push({ CategoryID: 0, BrandID: 0, SubCatID: SubCatID, SSID: 0, MaincategoryID: 0, GroupID: 0 })
    }
    else {
      var index1 = this.commonProductList.findIndex(x => x.SubCatID === SubCatID);
      if (index1 > -1) {
        this.commonProductList.splice(index1, 1);
      }
    }

    this.showsubsubcategorylist = [];
    for (var i = 0; i < this.commonProductList.length; i++) {
      for (var j = 0; j < this.subSubCategoriesList.length; j++) {
        if (this.subSubCategoriesList[j].SubCatID == this.commonProductList[i].SubCatID) {
          this.showsubsubcategorylist.push(this.subSubCategoriesList[j]);
        }
      }

    }
      var url = "/api/Admin/FilterProducts"
      var UploadFile = new FormData();
      UploadFile.append("Products", JSON.stringify(this.commonProductList));

    this.generalservice.PostData(url, UploadFile).then((data: any) => {
      debugger;
        //alert(JSON.stringify(data));
      this.FilteredAdvNormalProducts = [];
      this.FilteredAdvNormalProducts = data;

      if (this.BookingStatusid == 1) {
        this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == 1);
      }
      else if ((this.BookingStatusid == 2)) {
        this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == 2);
      }
      else {
        this.ShowProductsData = data;
      }
      });
   
  }
  
  Brands(item, BrandID) {
    {
     
      if (item.target.checked == true) {
        this.commonProductList.push({ CategoryID: 0, BrandID: BrandID, SubCatID: 0, SSID: 0, MaincategoryID: 0, GroupID: 0 })
      }
      else {
       
          var index1 = this.commonProductList.findIndex(x => x.BrandID === BrandID);
          if (index1 > -1) {
            this.commonProductList.splice(index1, 1);
          }
        }
      }

      var url = "/api/Admin/FilterProducts"
      var UploadFile = new FormData();
      UploadFile.append("Products", JSON.stringify(this.commonProductList));

      this.generalservice.PostData(url, UploadFile).then((data: any) => {
        debugger;
        //alert(JSON.stringify(data));
        this.FilteredAdvNormalProducts = [];
        this.FilteredAdvNormalProducts = data;

        if (this.BookingStatusid == 1) {
          this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == 1);
        }
        else if ((this.BookingStatusid == 2)) {
          this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == 2);
        }
        else {
          this.ShowProductsData = data;
        }

      });

    }
    
  subsubcategory(item, SSID)
  {
    if (item.target.checked == true) {
      this.commonProductList.push({ CategoryID: 0, BrandID: SSID, SubCatID: 0, SSID: 0, MaincategoryID: 0, GroupID: 0 })
    }
    else {

      var index1 = this.commonProductList.findIndex(x => x.SSID === SSID);
      if (index1 > -1) {
        this.commonProductList.splice(index1, 1);
      }
    }
  

      var url = "/api/Admin/FilterProducts"
var UploadFile = new FormData();
UploadFile.append("Products", JSON.stringify(this.commonProductList));

this.generalservice.PostData(url, UploadFile).then((data: any) => {
  debugger;
  //alert(JSON.stringify(data));
  this.FilteredAdvNormalProducts = [];
  this.FilteredAdvNormalProducts = data;

  if (this.BookingStatusid == 1) {
    this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == 1);
  }
  else if ((this.BookingStatusid == 2)) {
    this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == 2);
  }
  else {
    this.ShowProductsData = data;
  }

});

  }

  MainCategory(item, MainCatID) {
    debugger;
    this.spinner = true;
    this.Maincatid = MainCatID;
    let SelectedBrands = [];
    SelectedBrands = this.commonProductList.filter(a => a.BrandID != 0);   
    this.commonProductList = [];
    for (var i = 0; i < SelectedBrands.length; i++) {
      this.commonProductList.push({ CategoryID: 0, BrandID: SelectedBrands[i].BrandID, SubCatID: 0, SSID: 0, MaincategoryID: MainCatID, GroupID: 0 })
    }
    this.commonProductList.push({ CategoryID: 0, BrandID: 0, SubCatID: 0, SSID: 0, MaincategoryID: MainCatID, GroupID: 0 })

    if (this.groupid != null) {
      this.commonProductList.push({ CategoryID: 0, BrandID: 0, SubCatID: 0, SSID: 0, MaincategoryID: MainCatID, GroupID: this.groupid })

    }

    
       this.ShowCategoryList = [];
    this.ShowCategoryList = this.categorylist.filter((a => a.MainCategoryID == MainCatID))

    this.ShowSubCategoryList = []

    if (this.ShowCategoryList.length > 0) {
     // SubCatID

      for (var i = 0; i < this.ShowCategoryList.length; i++) {
        for (var j = 0; j < this.subcatlist.length; j++) {
          if (this.ShowCategoryList[i].ID == this.subcatlist[j].SubCatID) {
            this.ShowSubCategoryList.push(this.subcatlist[j]);
          }
        }

      }
    }
    debugger;

    this.showsubsubcategorylist = [];

    if (this.ShowSubCategoryList.length>0) {

    
      for (var i = 0; i < this.ShowCategoryList.length; i++) {
        for (var j = 0; j < this.subSubCategoriesList.length; j++) {
          if (this.ShowCategoryList[i].ID == this.subSubCategoriesList[j].SubCatID) {
            this.showsubsubcategorylist.push(this.subSubCategoriesList[j]);
          }
        }
      }
    }

    debugger;

    var url = "/api/Admin/FilterProducts"
    var UploadFile = new FormData();
    UploadFile.append("Products", JSON.stringify(this.commonProductList));

    this.generalservice.PostData(url, UploadFile).then((data: any) => {
      debugger;
      //alert(JSON.stringify(data));
      this.FilteredAdvNormalProducts = [];
      this.FilteredAdvNormalProducts = data;

      if (this.BookingStatusid == 1) {
        this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == 1);
      }
      else if ((this.BookingStatusid == 2)) {
        this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == 2);
      }
      else {
        this.ShowProductsData = data;
      }
      this.spinner = false;
    });
  }

  SelectGroup(item, id) {
    this.groupid = id;
    this.commonProductList.push({ CategoryID: 0, BrandID: 0, SubCatID: 0, SSID: 0, MaincategoryID: 0, GroupID: this.groupid })
    
    var url = "/api/Admin/FilterProducts"
    var UploadFile = new FormData();
    UploadFile.append("Products", JSON.stringify(this.commonProductList));

    this.generalservice.PostData(url, UploadFile).then((data: any) => {
      debugger;
      //alert(JSON.stringify(data));
      this.FilteredAdvNormalProducts = [];
      this.FilteredAdvNormalProducts = data;

      if (this.BookingStatusid == 1) {
        this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == 1);
      }
      else if ((this.BookingStatusid == 2)) {
        this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == 2);
      }
      else {
        this.ShowProductsData = data;
      }

    });
  }

    
  
  
  Addtocart(menu) {
    debugger;
    var url = 'api/Admin/ProductsDataBasedOnProductID?ProductID=' + menu.ProductID;
    this.generalservice.GetData(url).then((data: any) => {
      debugger
      var data = data;
      menu.Quantity = 1;
      let savecart: any = [];
      this.AddedItems = [];
      this.AddtocartItems = [];
      this.AddedItems = JSON.parse(localStorage.getItem('Productdetails'));
      this.AddtocartItems = this.AddedItems;
      if (this.AddtocartItems == null) {
        this.AddtocartItems = [];
      }
      if (this.AddedItems != null) {
        for (var i = 0; i < this.AddedItems.length; i++) {
          debugger
          if (this.AddedItems[i].ProductID == data.ProductID) {
            this.generalservice.ShowAlert('Warning', "Your Product Is alredy your cart", 'Warning');
            return false;
            //this.AddtocartItems.push(this.AddedItems[i]);
          }
        }

      }
      if (this.AddedItems != null) {
        localStorage.setItem("totalcartitems", JSON.stringify(this.AddedItems.length));
        for (var i = 0; i < this.AddedItems.length; i++) {
          debugger
          if (this.AddedItems[i].ProductID == data.ProductID) {
            check = false;
          }
        }
      }
      var check = true;

      if (check == true) {
        debugger
        this.AddtocartItems.push(data);
        localStorage.setItem('Productdetails', JSON.stringify(this.AddtocartItems));
        this.totalcartitems = this.AddtocartItems.length;
        localStorage.setItem('totalcartitems', JSON.stringify(this.AddtocartItems.length));
        this.userservice.edituser(this.AddtocartItems.length);
        this.generalservice.ShowAlert('Success', " your product is Addtocart Successfully", 'Success');
      }
      else if (check == false) {
        debugger
        this.AddtocartItems.push(data);
        this.generalservice.ShowAlert('Warning', "Your Product Is alredy your cart", 'Warning');
      }
      else {
        if (check == true) {
          debugger
          // this.AddtocartItems = [];
          this.AddtocartItems.push(data);
          localStorage.setItem('Productdetails', JSON.stringify(this.AddtocartItems));
          this.totalcartitems = this.AddtocartItems.length;
          localStorage.setItem('totalcartitems', JSON.stringify(this.AddtocartItems.length));
          this.user.edituser(this.AddtocartItems.length);
          this.generalservice.ShowAlert('Success', "plz your product is add Successfully", 'Success');
          this.RefershPage();

        }
      }
    });
  }
  RefershPage() {
    debugger
    localStorage.setItem("totalcartitems", JSON.stringify(this.AddtocartItems));
    debugger
    this.userservice.edituser(this.AddtocartItems.length);
    }

  
  ProductDetails(item) { 
    debugger
    //var url = 'api/Admin/ProductsDataBasedOnProductID?ProductID=' + item.ProductID;
    //this.generalservice.GetData(url).then((data: any) => {
    //  debugger
    //  var data = data;
    //  this.productPrice1 = data[0].AdminPrice;
   
   // var data = this.products.filter((a => a.ProductID == item.ProductID));
   // localStorage.setItem("ProductsDetails", JSON.stringify(data));
    localStorage.setItem('ProductsDetails', JSON.stringify(item));
    //this.router.navigateByUrl('/productdetails')
      this.router.navigate(['/productdetails', item.ProductName, item.ProductID]);
   // this.router.navigateByUrl('/Addtocart');
    //});
  }


  AdvNormal(event, id) {
    debugger
    //this.BookingStatusid = id;
    //this.FilteredAdvNormalProducts = this.ShowProductsData;
    //this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == id);

    var url = "/api/Admin/FilterProducts"
    var UploadFile = new FormData();
    UploadFile.append("Products", JSON.stringify(this.commonProductList));

    this.generalservice.PostData(url, UploadFile).then((data: any) => {
      debugger;
      //alert(JSON.stringify(data));
      this.FilteredAdvNormalProducts = [];
      this.ShowProductsData = [];
      this.FilteredAdvNormalProducts = data;
      this.ShowProductsData = this.FilteredAdvNormalProducts.filter(a => a.BookingStatus == id);
    });
  }
  }



          







      
      




    
      
    


  

  


