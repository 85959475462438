import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralservicesService } from '../../generalservice';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { Http, ResponseContentType } from '@angular/http';
import { map, throttle } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
declare var $: any;





@Component({
  selector: 'app-myorders',
  templateUrl: './myorders.component.html',
  styleUrls: ['./myorders.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyordersComponent implements OnInit {
  public router: Router;
  myorders: any;
  RegId: any;
  LoginDetails: any;
  Logindet2: string;
  dta: any;
  Date1: any;
  Date: any;
  countOrders1: any;
  Logindet: any;
  AccessoryId: any;

  Reason: any;
  review: any;
  OrderID: any;
  rating: any;
  CustomerOrders: any;
  HomeURL: any;
  CustomerOrders1: any;
  countOrders: any;
  ShowOrders: boolean = false;
  popup: boolean = false;
  i: any;
  starList: boolean[] = [true, true, true, true, true];
  Alertmessage: any;
  fileUrl;
    LoginData: any;
  constructor(public http: HttpClient, public httpc: Http, public formBuilder: FormBuilder,
    public httpClient: HttpClient, private sanitizer: DomSanitizer, private datePipe: DatePipe
    ,router: Router, public generalService: GeneralservicesService) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });
    this.LoginData = JSON.parse(localStorage.getItem("LoginDetails"));
    if (this.LoginData == null) {
      this.router.navigateByUrl('/');
     
    }
  }

  ngOnInit() {
    this.getMyOrders()
   // window.location.reload(true);api/Values/Kissancustmur_getorders_vendor
  }


  getMyOrders() {
    debugger;
      debugger;
    var UploadFile = new FormData();
    this.LoginDetails = JSON.parse(localStorage.getItem('UserLoginDetails'));
    this.RegId = this.LoginDetails.ID;
      UploadFile.append("regid", this.RegId);
      var url = "api/Values/Get_Customer_Orders_BasedOnRegId";
      this.generalService.PostData(url, UploadFile).then((Object: any) => {
        debugger;
        this.myorders = Object;
        for (var i = 0; i < this.myorders.length; i++) {
          if (this.myorders[i].StatusName == 'Accepted' || this.myorders[i].StatusName == 'Pending') {

            this.myorders[i].StatusName = 'Booked';
          }
          else if (this.myorders[i].StatusName == 'Pickup boy delivered' || this.myorders[i].StatusName == 'PickupBoy Received'

            || this.myorders[i].StatusName == 'Pickup boy Accepted' || this.myorders[i].StatusName == 'Verified') {
            this.myorders[i].StatusName = 'Packed';
          }
          else if (this.myorders[i].StatusName == 'DeliveryBoy Assign') {
            this.myorders[i].StatusName = 'Yet to delivery';
          }
        }

      }, err => {

      });
  }
    ngAfterViewInit() {
      document.getElementById('preloader').classList.add('hide');
  }



  back() {
    this.ShowOrders = false;
  }
  GetMyOrders() {
    debugger;
    var UploadFile = new FormData();
    this.Logindet = JSON.parse(sessionStorage.getItem("LoginDetails"));
    UploadFile.append("CustId", this.Logindet.Id);
    var url = "api/Values/Get_MyOrders"
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger;
      this.CustomerOrders1 = data;
      for (var i = 0; i < this.CustomerOrders1.length; i++) {
        if (this.CustomerOrders1[i].StatusName == 'Accepted' || this.CustomerOrders1[i].StatusName == 'Pending') {

          this.CustomerOrders1[i].StatusName = 'Booked';
        }
        else if (this.CustomerOrders1[i].StatusName == 'Pickup boy delivered' || this.CustomerOrders1[i].StatusName == 'PickupBoy Received'

          || this.CustomerOrders1[i].StatusName == 'Pickup boy Accepted' || this.CustomerOrders1[i].StatusName == 'Verified') {
          this.CustomerOrders1[i].StatusName = 'Packed';
        }
        else if (this.CustomerOrders1[i].StatusName == 'DeliveryBoy Assign') {
          this.CustomerOrders1[i].StatusName = 'Yet to delivery';
        }
      }

      this.countOrders1 = this.CustomerOrders1.length;
    });
  }

  ShowOrderDetails(OrderId) {
    debugger;
    var UploadFile = new FormData();
    UploadFile.append("OrderID", OrderId);
    var url = "api/Admin/GetOrdersDetailsBasedOrderId"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.CustomerOrders = data;

      debugger;

      //var Month = this.Date.getMonth() + 1;
      //var Date1 = this.Date.getDate() + 1;

      for (var i = 0; i < this.CustomerOrders.length; i++) {
        if ( this.CustomerOrders[i].StatusName == 'Pending') {

          this.CustomerOrders[i].StatusName = 'Booked';
        }

        else if (this.CustomerOrders[i].StatusName == 'Accept'|| this.CustomerOrders[i].StatusName == 'PickUp') {
          this.CustomerOrders[i].StatusName = 'Shipped';
        }
        else if (this.CustomerOrders[i].StatusName == 'Completed') {
          this.CustomerOrders[i].StatusName = 'Delivered';
        }
      }
      this.ShowOrders = true;
      this.countOrders = this.CustomerOrders.length;
    });
  }

  test() {
    debugger
    $('#myModal').modal('show');


  }
  test2(menu) {
    debugger
    this.dta = menu.ID;

    $('#myModal1').modal('show');
  }
  Cancelpo() {
    $('#myModal1').modal('hide');
  }

  /*popup for Customer Return order reason */
  ReturnOrderReason() {
    $('#myModal3').modal('show');
  }
  /*end region*/


  /////// star functionality...........commented by @nil

  setStar(data: any, menu) {
    debugger;
    this.OrderID = menu.OrderId
    this.AccessoryId = menu.AccessoryId
    this.rating = data + 1;
    for (var i = 0; i <= 4; i++) {
      if (i <= data) {
        this.starList[i] = false;
      }
      else {
        this.starList[i] = true;
      }
    }
  }
  /////// save ratings & reviews ......commented by @nil

  saveRaingsReviews(Review) {

    if (Review == null || Review == undefined || Review == "" || this.rating == "" || this.rating == undefined || this.rating == null) {

      this.generalService.ShowAlert('INFO', 'All * fileds are Mandatory.', 'info')
    }
    else {
      var UploadFile = new FormData();
      UploadFile.append("Rating", this.rating);
      UploadFile.append("Review", Review);
      UploadFile.append("AccId", this.AccessoryId);
      UploadFile.append("OrderID", this.OrderID);
      UploadFile.append("CustomerID", this.Logindet.Id);
      var url = "api/Values/Ratings";
      this.generalService.PostData(url, UploadFile).then(data => {
        if (data == "SUCCESS") {
          $('#myModal').modal('hide');
          this.review = "";
          this.starList = [true, true, true, true, true];
          this.generalService.ShowAlert('SUCCESS', 'Thank You for your feedback', 'success')

        }
        else {
          this.generalService.ShowAlert('ERROR', data, 'error')
        }
      }, err => {
        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      });


    }
  }
  //////////// cancel order reason ...........comented by @nil
  cancelReason() {
    $('#myModal2').modal('show');
    $('#myModal1').modal('hide');

    debugger;
  }
  test3() {
    $('#myModal3').modal('show');
  }


  ////////// customer cancel order...............commented by @nil

  Cancel(menu, Reason) {
    debugger;
    if (Reason == null || Reason == undefined || Reason == "") {
      this.generalService.ShowAlert('INFO', 'Please Enter Valid reason', 'info')
    }
    else {
      var UploadFile = new FormData();
      UploadFile.append("ID", this.dta);
      UploadFile.append("StatusId", "5");
      UploadFile.append("CancelResaon", Reason);

      var url = "api/Values/CancelOrder";
      this.generalService.PostData(url, UploadFile).then(data => {
        if (data == "SUCCESS") {
          this.ngOnInit();
          $('#myModal2').modal('hide');
          this.Reason = "";
          this.generalService.ShowAlert('SUCCESS', 'Order Cancelled successfully', 'success')
          this.sendsmstocustomercancelorders(menu.CustomerName, menu.VendorName, menu.CMobile, menu.VendorMobile, menu.OrderNO, menu.AccessoryName)
          this.sendsmstovendor(menu.CustomerName, menu.VendorName, menu.CMobile, menu.VendorMobile, menu.OrderNO, menu.AccessoryName)
        }

        else {
          this.generalService.ShowAlert('ERROR', data, 'error')
        }
      }, err => {
        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      });
    }

    this.SendNotificationOrder(menu.Token, menu.OrderNO, menu.VendorName)
  }

  /*SEND NOTIFICATION FOR VENDOR WHEN CUSTOMER CANCEL THE ORDER*/
  SendNotificationOrder(Token, orderno, name) {
    var sendorName = "HGS"
    var description = 'Hi' + name + 'got a cancel order from orderno:' + orderno;
    var url = this.HomeURL + '/api/Admin/Send_HGS_Notification?Token=' + Token + "&Description=" + description + "&SenderName=" + sendorName + "&OrderNo=" + orderno + "";
    this.http.get(url).subscribe(data => {
      if (data == "Success") {

      }
    });
  }
  /*END CODE*/

  /*Customer return order*/
  SaveReturnOrderReason(menu, Reason) {
    debugger;
    if (Reason == null || Reason == undefined || Reason == "") {
      this.generalService.ShowAlert('INFO', 'Please Enter Valid reason', 'info')
    }
    else {
      var UploadFile = new FormData();
      UploadFile.append("ID", menu.ID);
      UploadFile.append("StatusId", "8");
      UploadFile.append("Resaon", Reason);

      var url = "api/Values/ReturnOrder";
      this.generalService.PostData(url, UploadFile).then(data => {
        if (data == "SUCCESS") {
          this.ngOnInit();
          $('#myModal3').modal('hide');
          this.Reason = "";
          this.generalService.ShowAlert('SUCCESS', 'Return Order successfully', 'success')
          //this.sendsmstocustomercancelorders(menu.CustomerName, menu.VendorName, menu.CMobile, menu.VendorMobile, menu.OrderNO, menu.AccessoryName)
        }

        else {
          this.generalService.ShowAlert('ERROR', data, 'error')
        }
      }, err => {
        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      });
    }

  }
  /*#endregion*/

  ////////////// DownLoad Pdf..............Commented by @nil

  DownloadInvoice(Pdf) {
    var PdfFile = this.HomeURL + Pdf;

  }

  /*send sms to customer when customer cancel own orders*/
  sendsmstocustomercancelorders(CustName, VendName, Cmobile, VendMobile, OrderNO, AccessoryName) {
    debugger;
    var UploadFile = new FormData();
    UploadFile.append("CustName", CustName);
    UploadFile.append("Vname", VendName);
    UploadFile.append("Mobile", Cmobile);
    UploadFile.append("Vmobile", VendMobile);
    UploadFile.append("OrederId", OrderNO);
    UploadFile.append("AccessoryName", AccessoryName);
    var url = '/api/Admin/SendSms_ToCustomerCancelorder';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

    });
  }
  /*end code*/
  //SendSms_ToCustomerCancelorderTovendor




  sendsmstovendor(CustName, VendName, Cmobile, VendMobile, OrderNO, AccessoryName) {
    var UploadFile = new FormData();
    UploadFile.append("CustName", CustName);
    UploadFile.append("Vname", VendName);
    UploadFile.append("Mobile", Cmobile);
    UploadFile.append("Vmobile", VendMobile);
    UploadFile.append("OrederId", OrderNO);
    UploadFile.append("AccessoryName", AccessoryName);
    var url = '/api/Admin/SendSms_ToCustomerCancelorderTovendor';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

    });
  }

  downloadFile(path) {
    debugger;
    if (path != '') {
      var PdfFile = this.HomeURL + path;
      window.open(PdfFile, "_blank");
      return this.httpc.get(PdfFile, { responseType: ResponseContentType.Blob }).pipe(map(res => {
        debugger
        return {
          filename: 'Invoice' + path + '.pdf',
          data: res.blob()
        };
      })).subscribe(res => {
        debugger;
        console.log('start download:', res);
        var url = window.URL.createObjectURL(res.data);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.')
      });
    } else {
      this.generalService.ShowAlert('ERROR', 'There is no invoice', 'error')
    }
  }

  //downloadFile(path: Response) {
  //  debugger
  //  var PdfFile = this.HomeURL + path;
  //  const blob = new Blob([PdfFile], { type: 'application/pdf' });
  //  const url = window.URL.createObjectURL(blob);
  //  window.open(url);
  //}
  ////// download
  download(path) {
    debugger;
    var data = this.HomeURL + path;
    const blob = new Blob([data], { type: 'application/pdf' });

    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  //GetOrdersPdfPath(OrderId) {
  //  debugger
  //}
}





