import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.scss']
})
export class TermsandconditionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.toTop();

  }
  toTop() {
    //debugger
    window.scroll(0, 0);
  }
  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }
}
