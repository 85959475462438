import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, provideRoutes, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { GeneralservicesService } from '../../generalservice';
import { PageHeaderComponent } from '../page-header/page-header.component'
import { userservice} from '../userservice'
import { from } from 'rxjs';
import { debounce } from 'rxjs/operators';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [PageHeaderComponent, userservice]
})

export class HomeComponent implements OnInit {
  ScrollID: string;
  target: string;
  HomeUrl: any;
    ShowCategories: any;
  ShowProducts: any;
  Logindetails: any;
    addedItems: any;
    addtocartItem: any;
    totalcartItems: any;
  ShowAdvancedProducts: any;
  productID: number;
    ShowAll: boolean;

  constructor(public router: Router, public AR: ActivatedRoute, public user: userservice, public header: PageHeaderComponent, fb: FormBuilder, public http: HttpClient, public generalService: GeneralservicesService)
  {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
    this.Logindetails = JSON.parse(localStorage.getItem('LoginDetails'));
    this.ScrollID = sessionStorage.getItem('ScrollID'); 
   
  }


  ngOnInit() {

    //debugger
    this.toTop();
    this.GetCategories();
    this.GetProducts();
    if (this.ScrollID == '1') {
      this.target = 'Products';
     
    }

    navigator.geolocation.getCurrentPosition(position => { });
  }

  GetCategories() {
    //debugger
    var url = 'api/Admin/GetBrandsCategorysubcategories';
    
    this.generalService.GetData(url).then((data: any) => {
      //debugger
      if (data != null) {
        this.ShowCategories = data.MainCategoryList;
      }
      else {
        this.generalService.ShowAlert('ERROR', 'No data found', 'error');
      }
    })
  }

  GetProducts() {
    //debugger
    var url = '/api/Admin/Product_CRUD';
    var formdata = new FormData();
    formdata.append("ProductXml", "");
    formdata.append("ImageXml", "");
    formdata.append("SizesXml", "");
    formdata.append("ReletedProductXml", "");
    formdata.append("Flag", "5");
    this.generalService.PostData(url, formdata).then((data: any) => {
      //debugger
      if (data != null) {
        this.ShowProducts = data.filter(a => a.BookingStatus == 2);
        this.ShowAdvancedProducts = data.filter(a => a.BookingStatus == 1);
      }
    })
  }


  ViewAll() {
    //debugger
    this.ShowAll = true;
    //this.router.navigate(['/ShowDetails']);
  }

  ViewAll1() {
    debugger
    //this.ShowAll = true;
    this.router.navigate(['/ShowDetails']);
  }
  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

  //Addtocart(menu) {
  //  //debugger
  //  if (this.Logindetails == null || this.Logindetails == "") {
  //    this.router.navigateByUrl('/login');
  //  }
  //  else {
  //    localStorage.removeItem('Productdetails');
  //    localStorage.setItem('Productdetails', JSON.stringify(menu));
  //    localStorage.setItem('add', 'addtocart');
  //    this.router.navigateByUrl('/Addtocart');
  //  }
  //}

  Addtocart(menu) {
    //debugger
    let savecart: any = [];
    this.addedItems = [];
    this.addtocartItem = []
    this.addedItems = JSON.parse(localStorage.getItem('Productdetails'));
    if (this.addedItems != null) {
      if (this.addedItems.length>0) {
        this.addtocartItem.push(this.addedItems);
      }
    }
    //var newaddeditem = this.addedItems;
    var check = true;
    if (this.addedItems != null ) {
      localStorage.setItem("totalcartitems", JSON.stringify(this.addedItems.length));
      for (var i = 0; i < this.addedItems.length; i++) {
        //debugger
        if (this.addedItems[i].ProductID == menu.ProductID) {
          check = false;
        }
      }
      if (check == true) {
        //debugger
        
        this.addtocartItem.push(menu);
        localStorage.setItem('Productdetails', JSON.stringify(this.addtocartItem));
        this.totalcartItems = this.addtocartItem.length;
        localStorage.setItem("totalcartitems", JSON.stringify(this.addtocartItem.length));
        this.user.edituser(this.addtocartItem.length);
        this.generalService.ShowAlert('SUCCESS', "Product Added to Cart  Successfully", 'success');
      }
      else if (check == false) {
        this.addtocartItem.push(menu);
        this.generalService.ShowAlert('Warning', "This Item is Already in your Cart", 'Warning');
        //this.totalcartItems = this.addtocartItem.length;
        //localStorage.setItem('Productdetails', JSON.stringify(menu));
        //localStorage.setItem("totalcartitems", JSON.stringify(this.addtocartItem.length));
        //this.header.getcartitems(this.addtocartItem.length);
        //this.generalService.ShowAlert('SUCCESS', "Product Added in Your cart Successfully", 'success');
      }

    }
    else {
      if (check == true) {
        //debugger
       
        this.addtocartItem = []
        this.addtocartItem.push(menu);
        localStorage.setItem('Productdetails', JSON.stringify(this.addtocartItem));
        this.totalcartItems = this.addtocartItem.length;
        localStorage.setItem("totalcartitems", JSON.stringify(this.addtocartItem.length));
        this.user.edituser(this.addtocartItem.length);
        this.generalService.ShowAlert('SUCCESS', "Product Added to Cart  Successfully", 'success');
        this.user.edituser(this.addtocartItem.length);
        this
      }
    }
    
  }
  ViewProducts(menu) {
    //debugger
    localStorage.setItem('ProductsDetails', JSON.stringify(menu));
    //this.router.navigateByUrl('/ViewProducts')
    this.router.navigate(['/productdetails', menu.ProductName, menu.ProductID]);
  }

  viewproducts() {
    this.router.navigate(['/productdetails']);
  }

  Product(item) {
    debugger;
    localStorage.setItem("Home", item.ID)
    this.router.navigateByUrl('/ShowDetails', item.ID)

  }
  //toTop() {
  //  //debugger
  //  document.getElementById(this.target).scrollIntoView();
  //}
  toTop() {
    //debugger
    window.scroll(0, 0);
  }
}
