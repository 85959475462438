import { Component, OnInit } from '@angular/core';
import { Router, provideRoutes } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { GeneralservicesService } from '../../generalservice';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
    products: any;
  HomeUrl: any;
  totalPrice: number = 0;
  LD: any;
  constructor(public router: Router, fb: FormBuilder, public http: HttpClient, public generalService: GeneralservicesService) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
    this.products = JSON.parse(localStorage.getItem('cartProducts'));
    this.LD = JSON.parse(localStorage.getItem('LoginDetails'));
    if (this.LD == null) {
      this.router.navigateByUrl('/Home');
    }
  }

  ngOnInit() {
    this.GetTotalPrice();
  }

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

  GetTotalPrice() {
    debugger
    for (var i = 0; i <= this.products.length; i++) {
      var price = 0;
      price = parseInt(this.products[i].AdminPrice) * parseInt(this.products[i].Quantity);
      this.totalPrice = this.totalPrice + price;
    }
  }

  placeOrder() {
    debugger
    var products = [];
    for (var i = 0; i < this.products.length; i++) {
      products.push({ SizeID: this.products[i].ProductID, RegID: this.LD.ID, Quantity: this.products[i].Quantity, PricePerQuantity: this.products[i].AdminPrice })
    }
    var UploadFile = new FormData();

    UploadFile.append("OrderXml", JSON.stringify(products));

    var url = "/api/Admin/InsertCustomerOrders"
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger;
      if (data == 'SUCCESS') {
        localStorage.removeItem('Productdetails');
        localStorage.removeItem('totalcartitems');
        this.router.navigateByUrl('/Home');
        this.generalService.ShowAlert('SUCCESS', 'Order Placed successfully', 'success')
      }
      else {
        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
}
