import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { PagelayoutComponent } from './pages/pagelayout/pagelayout.component';
import { PageHeaderComponent } from './pages/page-header/page-header.component';
import { HomeComponent } from './pages/home/home.component';
import { AddtocartComponent } from './pages/addtocart/addtocart.component';
//import {AboutUsComponent } from './pages/about-us/about-us.component'
import { ProductdetailsComponent } from './pages/productdetails/productdetails.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ShowDetailsComponent } from './pages/show-details/show-details.component';
import { VendorModuleModule } from './pages/vendor-module/vendor-module.module'
import { MyordersComponent } from './pages/myorders/myorders.component';
import { VendorRegistrationComponent } from './pages/vendor-registration/vendor-registration.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { ReturnpolicyComponent } from './pages/returnpolicy/returnpolicy.component';
import { TermsandconditionsComponent } from './pages/termsandconditions/termsandconditions.component'
import { MyWalletComponent } from './pages/my-wallet/my-wallet.component'
import { MyRewardsComponent } from './pages/my-rewards/my-rewards.component'


export const routes: Routes = [
  { path: '', redirectTo: 'Home', pathMatch: 'full' },
  {
    path: '', component: PagesComponent,
    children:[
      { path: 'Dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardModule', data: { breadcrumb: 'Dashboard' } },
      { path: 'Admin', loadChildren: './pages/Admin/admin.module#AdminModule', data: { breadcrumb: 'Admin' } },
      { path: 'Vendor', loadChildren: () => import('./pages/vendor-module/vendor-module.module').then(m => m.VendorModuleModule), data: { breadcrumb: 'Vendor' } },
      
    ]
  },
  {
    path: '',
    component: PagelayoutComponent,
    children: [
      { path: 'Home', component: HomeComponent },
      { path: 'Header', component: PageHeaderComponent },
      { path: 'Aboutus', component: AboutUsComponent },
      { path: 'Contactus', component: ContactUsComponent },
      { path: 'myorders', component: MyordersComponent },
      { path: 'user-profile', component: UserProfileComponent},
      { path: 'ShowDetails', component: ShowDetailsComponent },
      { path: 'productdetails/:name/:id', component: ProductdetailsComponent },
      { path: 'Addtocart', component: AddtocartComponent },
      { path: 'PrivacyPolicy', component: PrivacypolicyComponent },
      { path: 'vendorRegistation', component: VendorRegistrationComponent },
      { path: 'ReturnPolicy', component: ReturnpolicyComponent },
      { path: 'TermsConditions', component: TermsandconditionsComponent },
      { path: 'login', loadChildren: './pages/login/login.module#LoginModule' },
      { path: 'my-wallet', component: MyWalletComponent },
      { path: 'myrewards', component: MyRewardsComponent }
    ]
  },
 
  { path: '', loadChildren: './customer/customer.module#CustomerModule' },
  
  { path: 'register', loadChildren: './pages/register/register.module#RegisterModule' },
  
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    //preloadingStrategy: PreloadAllModules,
    useHash: true
});
