import { Component, OnInit } from '@angular/core';
import { GeneralservicesService } from '../../generalservice';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-my-wallet',
  templateUrl: './my-wallet.component.html',
  styleUrls: ['./my-wallet.component.scss']
})
export class MyWalletComponent implements OnInit {
  spinner: boolean = false;
  Wallet: any;
  cols: any;
  LoginDetails: any;
  RoleId: any;
  code: any;
  Logindetails: any;
  Wallet1: any;
  constructor(public formBuilder: FormBuilder, public http: HttpClient,
    public generalservice: GeneralservicesService, public router: Router) {
    //this.AddForm = this.formBuilder.group({
    //  MandalName: ['', Validators.required],
    //  DistrictName: ['', Validators.required],

    //})
    this.cols = [
      { field: 'Amount', header: 'Amount', width: '300px' },
      { field: 'ProductName', header: 'Product Name', width: '280px' },
      { field: 'Promocode', header: 'Promo Code', width: '280px' },
      
    ]
    this.Logindetails = JSON.parse(localStorage.getItem('LoginDetails'));
    if (this.Logindetails != null) {
      this.RoleId = this.Logindetails.RoleID;
      //this.GetUserRewardPoints();
    }
  }

  ngOnInit() {
    this.GetWallet();
  }

  GetWallet() {
    debugger;
    this.code = this.Logindetails.ID
    //this.spinner = true;
    var url = "/api/Admin/userPromocodeCurd";
    var UploadFile = new FormData();
    UploadFile.append("Flag", '2');
    UploadFile.append("Xml", '');
    UploadFile.append("User", this.code);
    this.generalservice.PostData(url, UploadFile).then((data: any) => {
      debugger;
      this.Wallet = data.List;
      this.Wallet1 = data.List1;
     // this.spinner = false;
    }, err => {
      this.generalservice.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
     // this.spinner = false;
    });
  }
  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

}
