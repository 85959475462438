import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { RequestOptions, Headers, Http } from '@angular/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import '../../../assets/smtp.js';
//declare let Email: any;
import Swal from 'sweetalert2';
import { GeneralservicesService } from 'src/app/generalservice';
import * as Email from '../../../assets/smtp.js';



@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  EnquiryAry: any=[];
  //ApiURL: any;
  ContactUsForm: FormGroup;
  spinner: boolean = false;
  constructor(public generalService: GeneralservicesService,public fb: FormBuilder) {
  //  this.ApiURL = 'http://localhost:51068/token';
    debugger
   // this.ApiURL= 'http://9roads.in:3002/validateUser'
    
  }

  ngOnInit() {
    debugger
    this.toTop();
    //this.ContactUs();
    this.ContactUsForm = this.fb.group({
      Name: ['', Validators.required],
      Mobile: ['', Validators.required],
      Email: ['', Validators.compose([Validators.required, Validators.email])],
      Comments: ['', Validators.required]
    });
  }

  //ContactUs() {
  //  debugger
  // //let body = {
  // //  //'grant_type': 'password',
  // //  //"username": 'Anurag',
  // //  //"password": '123456'

  // //  "grant_type=password&username='Anurag'&password='password'
  // // }

  //  let urlSearchParams = new URLSearchParams();
  //  urlSearchParams.set('grant_type', 'password');
  //  urlSearchParams.set('username', 'ravi9587');
  //  urlSearchParams.set('password', '18242958');

  //  let body = urlSearchParams.toString();

    


  //  let headersk = new HttpHeaders({
  //    'Content-Type': 'application/x-www-form-urlencoded',
  //    'Authorization': 'Basic dmlqYXlrdW1hckBnbWFpbC5jb206VmlqYXlAMTIzNA ==' 
  //  //  'Authorization': basic
  //    // headers.append('Authorization', this.basic);
  //  })
  // // let options = { headers: headersk };

  //  //let headers = new Headers();
  //  //headers.append('Content-Type', 'application/json');
  //  //headers.append('Authorization', 'Basic dmlqYXlrdW1hckBnbWFpbC5jb206VmlqYXlAMTIzNA==');
  //  //let options = new RequestOptions({ headers: headers });

  //  let headers = new Headers({ 'Accept': 'application/json' });
  //  headers.append('Authorization', 'Basic dmlqYXlrdW1hckBnbWFpbC5jb206VmlqYXlAMTIzNA==');
  //  headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Authorization');
  //  headers.append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, PATCH');
  //  headers.append('Access-Control-Allow-Origin', '*');

  //  let options = new RequestOptions({ headers: headers });
  //  this.http.post(this.ApiURL, body, options).subscribe(data => {
  //    debugger
  //  })
  //}


 // httpHeaders.append("Authorization", 'Basic dmlqYXlrdW1hckBnbWFpbC5jb206VmlqYXlAMTIzNA==');
  //onSubmit(form: any) {
  //  debugger;
  //  Email.send({
  //    SecureToken: "e9573f5c-3634-4a5f-bcf4-e5dac41cded3",
  //    //To: 'info@a2zserviz.com',
  //    To: 'umamaheswararaonagisetti@gmail.com',
  //    From: "gagriglobal@gmail.com",
  //    Subject: "Enquiry",
  //    Body: `
  //           <i>The enquiry details are as follows.</i> <br/> <b>Name: </b>${form.Name} <br />
  //           <b>Mobile: </b>${form.Mobile}<br /> <b>Email: </b>${form.Email}<br />
  //           <b>Message:</b> <br /> ${form.Comments} <br><br> `
  //  }).then(message => {
  //    debugger
  //    this.ContactUsForm.reset();
  //    Swal.fire(
  //      'SUCCESS',
  //      'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
  //      'success'
  //    )
  //  });

  //}


  onSubmit(form: any) {
    debugger;
    this.spinner = true;
      this.EnquiryAry = []; this.EnquiryAry.push({ Name: form.Name, Mobile: form.Mobile, Email: form.Email, Address: form.Comments })
      var UploadFile = new FormData();
      UploadFile.append("Details", JSON.stringify(this.EnquiryAry));
      var url = "/api/Values/EnquiryMailToAdmin"
      this.generalService.PostData(url, UploadFile).then((data: any) => {
        debugger;
        this.ContactUsForm.reset();
        Swal.fire(
          'SUCCESS',
          'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
          'success'
        )
        this.spinner = false;
      });
    this.spinner = false;
    }
  


  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }
  toTop() {
    //debugger
    window.scroll(0, 0);
  }

}
